import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { brandsReducer as brands, brandsSaga } from './blocklist/brands.duck';
import { termsReducer as terms, termsSaga } from './blocklist/terms.duck';
import { communicationsReducer as communications, communicationsSaga } from './communications/communications.duck';
import {
  deliveryFailuresReducer as deliveryFailures,
  deliveryFailuresSaga,
} from './deliveryFailures/deliveryFailures.duck';
import { fanlabReducer as fanlab, fanlabSaga } from './fanlab';
import { groupsReducer as groups, groupsSaga } from './groups/groups.duck';
import { layoutReducer as layout } from './layout';
import { platformsReducer as platforms, platformsSaga } from './platforms';
import { productsReducer as products, productsSaga, productReducer as product, productSaga } from './products';
import { channelReducer as channel, channelSaga } from './products/channel.duck';
import { skuTotalizerReducer as skuTotalizer, skuTotalizerSaga } from './products/sku-totalizers.duck';
import { sellersReducer as sellers, sellersSaga } from './sellers';
import { sellerReducer as seller, sellerSaga } from './sellers/seller.duck';
import { sellerLiteReducer as sellerLite, sellerLiteSaga } from './sellersLite/sellerLite.duck';
import { sellersLiteReducer as sellersLite, sellersLiteSaga } from './sellersLite/sellersLite.duck';
import { usersReducer as users, usersSaga } from './users/users.duck';
import { webhookReducer as webhook, webhookSaga } from './webhook';

export const rootReducer = combineReducers({
  sellers,
  seller,
  layout,
  products,
  skuTotalizer,
  product,
  users,
  groups,
  terms,
  brands,
  communications,
  sellersLite,
  sellerLite,
  webhook,
  platforms,
  fanlab,
  channel,
  deliveryFailures,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([
    sellersSaga(),
    sellerSaga(),
    productsSaga(),
    productSaga(),
    skuTotalizerSaga(),
    usersSaga(),
    groupsSaga(),
    termsSaga(),
    brandsSaga(),
    communicationsSaga(),
    sellersLiteSaga(),
    sellerLiteSaga(),
    webhookSaga(),
    platformsSaga(),
    fanlabSaga(),
    channelSaga(),
    deliveryFailuresSaga(),
  ]);
}
