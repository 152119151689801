import type { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ErrorMessages } from '../../../@types';
import { ProductsAction } from '../../../@types/products';
import { api } from '../../../services/api';

const API_SERVICE = process.env.REACT_APP_CATALOG_API_URL;

type Attr = {
  id: string;
  name: string;
  value: string;
  productId: string;
};

export interface IImage {
  id?: string;
  isMain?: boolean;
  skuMarketplaceId?: string;
  url?: string;
}

export interface IDimensions {
  dimensions?: number;
  height?: number;
  length?: number;
  weigth?: number;
  width?: number;
}

export interface IPrice {
  priceList?: number;
  priceSale?: number;
  skuMarketplaceId?: string;
}

export interface ISituation {
  disapprovalReasons?: string;
  situation?: string;
  skuSituationDescription?: string;
}

export interface IStock {
  quantity?: number;
  skuMarketplaceId?: string;
}

export interface IChannel {
  channelId: string;
  id: string;
  value: string;
  urlChannel: string;
}

export type SkuDetailState = {
  blockReason?: string;
  eanCode: string;
  id?: string;
  productId?: string;
  skuSeller?: string;
  status?: string;
  statusPtBr?: string;
  isbnCode?: string;
  urlRiachuelo?: string;
  variations?: Attr[];
  images?: IImage[];
  dimensions?: IDimensions;
  price?: IPrice;
  situation?: ISituation;
  stock?: IStock;
  categorySeller: string;
  channels?: IChannel[];
};

export type ProductDetailState = {
  brand?: string;
  categorySeller?: string;
  description?: string;
  id?: string;
  productSeller?: string;
  sellerId?: string;
  situation?: string;
  originType?: string;
  attributes: Attr[];
  title?: string;
  errorMessages: ErrorMessages;
  notFoundError?: boolean;
  fetching: boolean;
  success: boolean;
  sku?: SkuDetailState;
};

const initialState: ProductDetailState = {
  errorMessages: [],
  fetching: false,
  attributes: [],
  success: false,
};

const reducer: Reducer<ProductDetailState, ProductsAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@product/FETCH':
    case '@sku/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@product/FETCH_SUCCESS':
    case '@product/FETCH_ERROR':
    case '@sku/FETCH_SUCCESS':
    case '@sku/FETCH_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* fetchProductDetails({ payload: { id, sku } }: ProductsAction<any>) {
  try {
    const { data } = yield call(api.get, `${API_SERVICE}/products/${id}`);
    yield put({
      type: '@product/FETCH_SUCCESS',
      payload: {
        ...data,
        sku,
        success: true,
      },
    });
  } catch (error: any) {
    yield put({
      type: '@product/FETCH_ERROR',
      payload: {
        errorMessages: error?.data?.errorMessages,
        success: false,
        notFoundError: error?.status === 404,
      },
    });
  }
}

function* fetchSkuDetails({ payload: { id } }: ProductsAction<any>) {
  try {
    const { data } = yield call(api.get, `${API_SERVICE}/skus/${id}`);
    yield put({
      type: '@sku/FETCH_SUCCESS',
      payload: {
        sku: data,
        success: true,
      },
    });
  } catch (error: any) {
    yield put({
      type: '@sku/FETCH_ERROR',
      payload: {
        errorMessages: error?.data?.errorMessages,
        success: false,
        notFoundError: error?.status === 404,
      },
    });
  }
}

function* saga() {
  yield takeLatest('@product/FETCH', fetchProductDetails);
  yield takeLatest('@sku/FETCH', fetchSkuDetails);
}

export { reducer as productReducer, saga as productSaga };
