import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { LayoutState } from '../../../@types/layout';
import LoadingAlert from './LoadingAlert';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const renderSnackbarSeverity = (severity) => {
  switch (severity) {
    case 'success':
      return '#4caf50';
    case 'error':
      return '#f44336';
    case 'info':
      return '#2196f3';
    case 'warning':
      return '#ff9800';
  }
};

const AlertSnackbar = styled(Alert)`
  background-color: ${({ severity }) => renderSnackbarSeverity(severity)};
  color: #fff;
`;

const SnackbarComponent: React.FC<LayoutState> = ({ message, type, open }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        open: false,
      },
    });
  };

  return (
    <div className={classes.root}>
      <Snackbar autoHideDuration={8000} open={open} onClose={handleClose}>
        {type === 'loading' ? (
          <LoadingAlert message={message} handleClose={handleClose} />
        ) : (
          <AlertSnackbar variant="filled" onClose={handleClose} severity={type}>
            {message}
          </AlertSnackbar>
        )}
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
