import Keycloak, { KeycloakConfig } from 'keycloak-js';

const config: KeycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
} as KeycloakConfig;

const keycloak = Keycloak(config);

export default keycloak;
