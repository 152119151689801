import { Reducer } from 'react';
import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import isValidFilter from '@rchlo/storybook/core/shared/utils/isValidFilter';
import type { ErrorMessages, Pagination } from '../../../@types/index.d';
import type { ISeller, SellersRequest } from '../../../@types/sellers.d';
import { api } from '../../../services/api';

export type SellersState = Pagination<ISeller> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  search?: string;
  isActive?: string;
};

export type SellersAction<T> = Action<string> & {
  payload: T;
};

export type FetchSellersAction = SellersAction<SellersRequest>;

const initialState: SellersState = {
  fetching: false,
  success: false,
  errorMessages: [],
  items: [],
  pageIndex: 0,
  pageSize: 8,
  totalCount: 0,
  totalPages: 0,
  indexFrom: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

const sellersReducer: Reducer<SellersState, FetchSellersAction | any> = (state = initialState, { payload, type }) => {
  switch (type) {
    case '@sellers/CLEAN':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: false,
      };
    case '@sellers/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@sellers/FETCH_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@sellers/FETCH_ERROR':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

function* fetchSellers({ payload: { pageIndex = 0, pageSize = 8, search, isActive } }: FetchSellersAction) {
  const isFilter = isValidFilter({
    isActive,
    search,
  });

  try {
    const response = yield call(api.get, `${process.env.REACT_APP_SELLER_API_URL}/sellers`, {
      params: {
        pageSize,
        pageIndex,
        search,
        isActive,
      },
    });

    yield put({
      type: '@sellers/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        ...response?.data,
      },
    });
  } catch (error: any) {
    if (error.status === 404) {
      const [errorMessage] = error.data.errorMessages;
      yield put({
        type: '@sellers/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: !isFilter
                ? 'Não existem sellers para listar.'
                : 'Nenhum resultado encontrado, verifique os dados inseridos e tente novamente.',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@sellers/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
        },
      });
    }
  }
}

function* sellersSaga() {
  yield takeLatest('@sellers/FETCH', fetchSellers);
}

export { sellersReducer, sellersSaga };
