import type { Action, Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ErrorMessages, Situations } from '../../../@types';
import { PortalsTotalizerRequest } from '../../../@types/products';
import { api } from '../../../services/api';

const API_SERVICE = process.env.REACT_APP_CATALOG_API_URL;

export type TotalizersState = {
  situations: Situations;
  errorMessages: ErrorMessages;
  success: boolean;
  totalStockSum?: number;
  totalSkuCount: number;
  fetchingTotalizers: boolean;
};

export type TotalizersAction<T> = Action<string> & {
  payload: T;
};

export type FetchPortalsTotalizersAction = TotalizersAction<PortalsTotalizerRequest>;

const initialState: TotalizersState = {
  situations: { data: [] },
  errorMessages: [],
  success: true,
  totalStockSum: undefined,
  totalSkuCount: 0,
  fetchingTotalizers: false,
};

const reducer: Reducer<TotalizersState, TotalizersAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@sku-totalizers/FETCH':
      return {
        ...state,
        ...payload,
        fetchingTotalizers: true,
      };
    case '@sku-totalizers/FETCH_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@sku-totalizers/FETCH_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@sku-totalizers/CLEAN': {
      return initialState;
    }
    default:
      return state;
  }
};

function* fetchSituations({
  payload: { sellerId, search, isActive, situation, channel },
}: FetchPortalsTotalizersAction) {
  const status = isActive === 'true' ? 'Active' : isActive === 'false' ? 'Inactive' : null;
  try {
    const response = yield call(api.get, `${API_SERVICE}/skus/totalizers`, {
      params: {
        'seller-id': sellerId,
        search: search || '',
        situation,
        status,
        channel,
      },
    });

    if (response.success) {
      yield put({
        type: '@sku-totalizers/FETCH_SUCCESS',
        payload: {
          situations: {
            data: response.data.totalSkuPerSituations,
          },
          errorMessages: response.errorMessages,
          returnMessageType: response.returnMessageType,
          success: response.success,
          totalStockSum: response.data.totalStockSum,
          totalSkuCount: response.data.totalSkuCount,
          fetchingTotalizers: false,
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: '@sku-totalizers/FETCH_ERROR',
      payload: {
        situations: {
          data: [
            { key: 'Blocked' },
            { key: 'Published' },
            { key: 'Reproved' },
            { key: 'Validating' },
            { key: 'Publishing' },
            { key: 'Inactivated' },
          ],
        },
        errorMessages: error.data.errorMessages,
        returnMessageType: error.data.returnMessageType,
        success: error.data.success,
        fetchingTotalizers: false,
      },
    });
  }
}

function* saga() {
  yield takeLatest('@sku-totalizers/FETCH', fetchSituations);
}

export { reducer as skuTotalizerReducer, saga as skuTotalizerSaga };
