import { Reducer } from 'react';
import type { Action } from 'redux';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import type { ErrorMessages, Pagination, FeatureToggleList } from '../../../@types';
import type {
  CreateUserRequest,
  EditUserRequest,
  GetUserByIdRequest,
  toggleUserStatusRequest,
  ListUserRequest,
  User,
  DeleteUserRequest,
} from '../../../@types/users';
import { api } from '../../../services/api';

const API_SERVICE = `${process.env.REACT_APP_USER_MANAGEMENT_API_URL}`;
const API_BUSINESS_PARAMS = `${process.env.REACT_APP_BUSINESS_PARAMS_API_URL}`;

const msgEmailIsAlreadyInUse = 'Operation not effected. Email already registered.';
const msgUserIdAlreadyInUse = 'Operation not effected. UserIdentityProvider already registered.';

type Messages = {
  message: string;
};

export type UsersState = Pagination<User> & {
  fetching: boolean;
  submitting: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  persisted: boolean;
  selectedUser?: User;
  sellerNameToManagement?: string;
  featureToggleList?: FeatureToggleList;
};

export type UsersAction<T> = Action<string> & {
  payload: T;
};

export type ListUsersAction = UsersAction<ListUserRequest>;

export type CreateUserAction = UsersAction<CreateUserRequest>;

export type DeleteUserAction = UsersAction<DeleteUserRequest>;

export type GetUserByIdAction = UsersAction<GetUserByIdRequest>;

export type EditUserAction = UsersAction<EditUserRequest>;

export type toggleUserStatusAction = UsersAction<toggleUserStatusRequest>;

export type sendEmailToRecoveryPasswordAction = UsersAction<GetUserByIdRequest>;

export type queryParam = UsersAction<string>;

const initialState: UsersState = {
  fetching: false,
  submitting: false,
  success: false,
  items: [],
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  persisted: false,
  sellerNameToManagement: '',
  featureToggleList: {
    sapIdFeatureToggle: {
      defaultValue: false,
      isActive: false,
    },
    campaignsHistoryFeatureToggle: {
      defaultValue: false,
      isActive: false,
    },
    newCommissionSkuFeatureToggle: {
      defaultValue: false,
      isActive: false,
    },
  },
};

const usersReducer: Reducer<UsersState, UsersAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@users/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
        selectedUser: null,
      };
    case '@users/FETCH_SUCCESS':
      return {
        ...state,
        success: payload.success,
        errorMessages: payload.errorMessages,
        ...payload.data,
        fetching: false,
        persisted: false,
        selectedUser: null,
      };
    case '@user/SELECT_SELLER_NAME_TO_MANAGEMENT':
    case '@user/GET_BY_ID_SUCCESS':
    case '@user/TOOGLE_USER_STATUS':
    case '@user/TOOGLE_USER_STATUS_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@user/CREATE_SUCCESS':
    case '@user/EDIT_SUCCESS':
      return {
        ...state,
        persisted: true,
        submitting: false,
      };
    case '@user/FORM_CLEAN':
      return {
        ...state,
        fetching: true,
        selectedUser: null,
      };
    case '@user/FORM_SUBMITTING':
      return {
        ...state,
        submitting: true,
      };
    case '@users/FETCH_ERROR':
    case '@user/CREATE_ERROR':
    case '@user/EDIT_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        submitting: false,
        persisted: false,
      };
    case '@user/VERIFY_BUSINESS_PARAM':
      return {
        ...state,
        ...payload,
      };
    case '@user/VERIFY_BUSINESS_PARAM_SUCCESS':
      return {
        ...state,
        featureToggleList: { ...state.featureToggleList, ...payload.featureToggleList },
      };
    default:
      return state;
  }
};

function* listUsers({ payload: { pageIndex = 0, pageSize = 20, sellerId, isComissionFlow } }: ListUsersAction) {
  try {
    const responsePayload = yield call(api.get, `${API_SERVICE}/users/groups`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
        sellerId,
      },
    });
    if (responsePayload) {
      yield put({
        type: '@users/FETCH_SUCCESS',
        payload: responsePayload,
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao listar os usuários.`,
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error: any) {
    if (error.status === 404 && !isComissionFlow) {
      const [errorMessage] = error.data.errorMessages;
      yield put({
        type: '@users/FETCH_ERROR',
        payload: {
          ...error.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: 'Não existem usuários para listar.',
            },
          ],
          items: [],
          fetching: false,
        },
      });
    } else if (!isComissionFlow) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao listar os usuários.`,
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* createUser({ payload }: CreateUserAction) {
  yield put({ type: '@user/FORM_SUBMITTING' });

  try {
    const responseCreateUser = yield call(api.post, `${API_SERVICE}/users`, payload);

    if (responseCreateUser) {
      const responseCreateUserGroup = yield call(
        api.post,
        `${API_SERVICE}/users/${responseCreateUser.data.id}/groups/${payload.group}`,
        {},
      );

      if (responseCreateUserGroup) {
        const user = responseCreateUser.data;
        const [firstName] = user.fullName.split(' ');

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            errorMessage: `Usuário ${firstName} adicionado com sucesso`,
            typeMessage: 'success',
            openMessage: true,
          },
        });

        yield put({
          type: '@user/CREATE_SUCCESS',
          payload: responseCreateUserGroup,
        });

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: `Usuário ${payload.fullName} criado com sucesso!`,
            type: 'success',
            open: true,
          },
        });
      } else {
        yield put({
          type: '@user/CREATE_ERROR',
        });

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: `Ocorreu um erro ao criar o grupo do usuário ${payload.fullName}.`,
            type: 'error',
            open: true,
          },
        });
      }
    } else {
      yield put({
        type: '@user/CREATE_ERROR',
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao criar o usuário ${payload.fullName}.`,
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error: any) {
    let messageErrror;
    const errorMessages = error?.data?.errorMessages;

    if (errorMessages) {
      const [emailIsAlreadyInUse] = errorMessages.filter(
        (e: Messages) => e.message === msgUserIdAlreadyInUse || msgEmailIsAlreadyInUse,
      );

      if (emailIsAlreadyInUse) {
        messageErrror = 'Erro ao adicionar usuário. E-mail já cadastrado.';
      }
    }

    yield put({
      type: '@user/CREATE_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: messageErrror ?? `Ocorreu um erro ao criar o usuário ${payload.fullName}.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* getUserById({ payload: { id } }: GetUserByIdAction) {
  yield put({ type: '@user/FORM_CLEAN' });

  let groupId = '';

  try {
    const payloadUser = yield call(api.get, `${API_SERVICE}/users/${id}`);

    try {
      const payloadUserGroup = yield call(api.get, `${API_SERVICE}/users/${id}/groups`, {
        params: {
          'page-index': 0,
          'page-size': 1,
        },
      });

      if (payloadUserGroup) {
        groupId = payloadUserGroup.data.items[0].groupId;
      }
    } catch (error) {
      // bloco catch vazio para ignorar erros
      // caso o usuário não tenha grupo associado,
      // permitindo a edição do mesmo
    }

    if (payloadUser) {
      yield put({
        type: '@user/GET_BY_ID_SUCCESS',
        payload: {
          success: payloadUser.success,
          errorMessages: payloadUser.errorMessages,
          selectedUser: { ...payloadUser.data, group: { id: groupId } },
          fetching: false,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao consultar o usuário.`,
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Ocorreu um erro ao consultar o usuário.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* editUser({ payload }: EditUserAction) {
  yield put({ type: '@user/FORM_SUBMITTING' });

  try {
    const responseEditUser = yield call(api.put, `${API_SERVICE}/users/${payload.id}`, payload);

    if (responseEditUser) {
      const responseEditUserGroup = yield call(
        api.post,
        `${API_SERVICE}/users/${responseEditUser.data.id}/groups/${payload.group}`,
      );

      if (responseEditUserGroup) {
        yield put({
          type: '@user/EDIT_SUCCESS',
          payload: responseEditUserGroup,
        });

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: `Usuário ${payload.fullName} editado com sucesso!`,
            type: 'success',
            open: true,
          },
        });
      } else {
        yield put({
          type: '@user/EDIT_ERROR',
        });

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: `Ocorreu um erro ao editar o usuário ${payload.fullName}.`,
            type: 'error',
            open: true,
          },
        });
      }
    } else {
      yield put({
        type: '@user/EDIT_ERROR',
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao editar o usuário ${payload.fullName}.`,
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error: any) {
    let messageErrror;
    const errorMessages = error?.data?.errorMessages;

    if (errorMessages) {
      const [emailIsAlreadyInUse] = errorMessages.filter(
        (e: Messages) => e.message === msgUserIdAlreadyInUse || msgEmailIsAlreadyInUse,
      );

      if (emailIsAlreadyInUse) {
        messageErrror = 'Erro ao editar usuário. E-mail já cadastrado.';
      }
    }

    yield put({
      type: '@user/EDIT_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: messageErrror ?? `Ocorreu um erro ao editar o usuário ${payload.fullName}.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* toggleUserStatus({ payload }: toggleUserStatusAction) {
  try {
    const { users } = yield select();
    const { enabled, id } = payload;

    const response = yield call(api.patch, `${API_SERVICE}/users/${id}`, { enable: enabled });

    if (response.success === true) {
      const enabledMessage = enabled === true ? 'ativado' : 'inativado';

      const newItems = users.items?.map((user: User) => {
        if (user.id === id) {
          return { ...user, enabled };
        } else {
          return user;
        }
      });

      yield put({
        type: '@user/TOOGLE_USER_STATUS_SUCCESS',
        payload: {
          ...users,
          success: response.success,
          errorMessages: response.errorMessages,
          fetching: false,
          items: newItems,
        },
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Usuário ${enabledMessage} com sucesso!`,
          type: 'success',
          open: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Algo deu errado com sua operação!`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* sendEmailToRecoveryPassword({ payload }: sendEmailToRecoveryPasswordAction) {
  try {
    const { id } = payload;

    const response = yield call(api.put, `${API_SERVICE}/users/${id}/reset-password`);

    if (response?.success === true) {
      yield put({
        type: '@user/TOOGLE_USER_STATUS_SUCCESS',
        payload: {
          success: response.success,
          errorMessages: response.errorMessages,
          fetching: false,
        },
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `E-mail para recuperação de senha enviado com sucesso!`,
          type: 'success',
          open: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Algo deu errado com sua operação!`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* verifyBusinessParam({ payload }: queryParam) {
  try {
    const response = yield call(api.get, `${API_BUSINESS_PARAMS}/rules/${payload}`);
    const featureToggleResponse = {
      defaultValue: response.data.defaultValue === 'true' || response.data.defaultValue === true ? true : false,
      isActive: response.data.isActive,
    };
    let featureToggleListPayload = {};

    switch (payload) {
      case 'FeatureToggle_AutomatedSellerRegistrationInSap':
        featureToggleListPayload = { sapIdFeatureToggle: { ...featureToggleResponse } };
        break;
      case 'FeatureToggle_EnableCampaignsHistory':
        featureToggleListPayload = { campaignsHistoryFeatureToggle: { ...featureToggleResponse } };
        break;
      case 'FeatureToggle_NewCommissionSkuForm':
        featureToggleListPayload = { newCommissionSkuFeatureToggle: { ...featureToggleResponse } };
        break;
      default:
        featureToggleListPayload = { enableAnticipationActivation: { ...featureToggleResponse } };
        break;
    }
    yield put({
      type: '@user/VERIFY_BUSINESS_PARAM_SUCCESS',
      payload: {
        featureToggleList: {
          ...featureToggleListPayload,
        },
      },
    });
  } catch (error) {
    yield put({
      type: '@user/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        featureToggleList: {
          sapIdFeatureToggle: {
            defaultValue: false,
            isActive: false,
          },
        },
      },
    });
  }
}

function* deleteUser({ payload: { sellerId, userId } }: DeleteUserAction) {
  try {
    yield call(api.delete, `${API_SERVICE}/users/${userId}/sellers/${sellerId}`);

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Usuário removido com sucesso!`,
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  } finally {
    yield put({
      type: '@users/FETCH',
      payload: {
        sellerId,
      },
    });
  }
}

function* usersSaga() {
  yield takeLatest('@users/FETCH', listUsers);
  yield takeLatest('@user/CREATE', createUser);
  yield takeLatest('@user/DELETE', deleteUser);
  yield takeLatest('@user/GET_BY_ID', getUserById);
  yield takeLatest('@user/EDIT', editUser);
  yield takeLatest('@user/TOOGLE_USER_STATUS', toggleUserStatus);
  yield takeLatest('@user/SEND_EMAIL_TO_RECOVERY_PASSWORD', sendEmailToRecoveryPassword);
  yield takeEvery('@user/VERIFY_BUSINESS_PARAM', verifyBusinessParam);
}

export { usersReducer, usersSaga };
