import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import normalizeParams from '../shared/utils/normalizeParams';

const { REACT_APP_API_BASEPATH_URL } = process.env;
const timeout = 30000;

const api = axios.create({
  baseURL: REACT_APP_API_BASEPATH_URL,
  timeout,
});

const checkRequestCanceled = (err: AxiosError) => {
  const parsedError = JSON.parse(JSON.stringify(err));
  return parsedError.code === 'ECONNABORTED';
};

const checkNetworkError = (err: AxiosError) => {
  const parsedError = JSON.parse(JSON.stringify(err));
  return parsedError.message === 'Network Error';
};

const onRequestSuccess = (response: AxiosResponse) => response.data;

const onRequestError = (err: AxiosError) => {
  const status = err.response ? err.response.status : null;

  if (checkRequestCanceled(err)) {
    // fazer algo se alguma requisição foi cancelada
  }

  if (checkNetworkError(err)) {
    // window.location.href = '/error'
  }

  const responseError = Promise.reject<{ status: number; data?: any }>({ status, data: err.response?.data });
  switch (status) {
    case 401:
    case 403:
      if (
        window.location.pathname !== '/acesso-negado' &&
        err.response?.config.url?.indexOf('integrations/vtex') === -1
      ) {
        window.location.href = '/acesso-negado';
      }
      return responseError;
    case 500:
      if (window.location.pathname !== '/error' && err.response?.config.url?.indexOf('integrations/vtex') === -1) {
        setTimeout(() => {
          window.location.href = '/error';
        }, 2000);
      }
      return responseError;
    default:
      return responseError;
  }
};

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = sessionStorage.getItem('@rchlo/admin/access_token');

  config.headers = {
    'Content-Type': 'application/json',
    Authorization: `bearer ${token}`,
  };

  if (config?.params) {
    config.params = { ...normalizeParams(config.params) };
  }

  return config;
});

api.interceptors.response.use(onRequestSuccess, onRequestError);

export { api };
