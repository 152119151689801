import { KeycloakInstance } from 'keycloak-js';

const goToLoginPage = (keycloak: KeycloakInstance) => {
  keycloak.login({
    redirectUri: window.location.href,
    idpHint: process.env.REACT_APP_KEYCLOAK_IDP_HINT,
  });

  return null;
};

export default goToLoginPage;
