import type { Reducer } from 'redux';
import { call, all, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { BusinessParamsResponse } from '../../../@types/businessParams';
import {
  IPortalsProductBase,
  ISKU,
  IReason,
  ProductsState,
  ProductsAction,
  FetchBlockSkusAction,
  FetchDisapprovalReasonsAction,
  FetchPortalsProductsAction,
  FetchPortalsProductsUrl,
  FetchSellerAction,
  FetchUpdateSkusAction,
  FetchValidateSkusAction,
  FetchBlockCatalogAction,
} from '../../../@types/products';
import { api } from '../../../services/api';

const {
  REACT_APP_CATALOG_API_URL,
  REACT_APP_CORE_API_URL,
  REACT_APP_SELLER_API_URL,
  REACT_APP_VTEX_NOTIFICATION_URL,
  REACT_APP_BUSINESS_PARAMS_API_URL,
} = process.env;

const initialState: ProductsState = {
  fetching: false,
  success: false,
  items: [],
  checked: false,
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  scrollPosition: 0,
  fetchReasons: true,
  fetchBlockCatalog: false,
};

const reducer: Reducer<ProductsState, ProductsAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@products/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
        pageIndex: 0,
      };
    case '@products/BLOCK_CATALOG':
      return {
        ...state,
        ...payload,
        fetchBlockCatalog: true,
      };
    case '@products/FETCH_SUCCESS':
    case '@products/FETCH_ERROR':
    case '@products/VERIFY_BUSINESS_PARAM_SUCCESS':
    case '@products/VERIFY_BUSINESS_PARAM_ERROR':
    case '@skus/VERIFY_URL':
    case '@skus/URL_ERROR':
    case '@skus/DISAPPROVAL_REASONS':
    case '@products/BLOCK_CATALOG_SUCCESS':
    case '@products/BLOCK_CATALOG_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@products/CLEAN': {
      return initialState;
    }
    case '@skus/URL_SUCCESS': {
      return {
        ...payload,
        ...state,
        items: state.items.map((item) => {
          if (item.id === payload.productId) {
            return {
              ...item,
              skus: item.skus.map((sku) => {
                if (sku.id === payload.item.id) {
                  return {
                    ...sku,
                    validUrl: payload.item.validUrl,
                  };
                } else {
                  return sku;
                }
              }),
            };
          } else {
            return item;
          }
        }),
      };
    }
    case '@skus/DISAPPROVAL_REASONS_SUCCESS': {
      return {
        ...payload,
        ...state,
        items: payload.items,
        fetchReasons: false,
      };
    }
    case '@skus/DISAPPROVAL_REASONS_ERROR': {
      return {
        ...payload,
        ...state,
        fetchReasons: false,
      };
    }
    default:
      return state;
  }
};

function* verifyUrl({ payload: { urlRiachuelo, productId, id } }: FetchPortalsProductsUrl) {
  try {
    yield call(api.get, `${REACT_APP_CORE_API_URL}/urls?url=${urlRiachuelo}`, { timeout: 3000 });

    yield put({
      type: '@skus/URL_SUCCESS',
      payload: {
        productId,
        item: {
          id,
          validUrl: true,
        },
      },
    });
  } catch {
    yield put({
      type: '@skus/URL_ERROR',
      payload: {},
    });
  }
}

function* fetchPortalsProducts({
  payload: { pageSize = 20, pageIndex = 0, situation, sellerId, search, isActive, scrollPosition, channel },
}: FetchPortalsProductsAction) {
  const status = isActive === 'true' ? 'Active' : isActive === 'false' ? 'Inactive' : null;
  try {
    const apiReturn = yield call(api.get, `${REACT_APP_CATALOG_API_URL}/portals/products`, {
      params: {
        'page-size': pageSize,
        'page-index': pageIndex,
        situation,
        'seller-id': sellerId,
        search: search || '',
        status,
        channel,
      },
    });

    const response = {
      ...apiReturn,
      data: {
        ...apiReturn.data,
        products: {
          ...apiReturn.data.products,
          scrollbarPosition: scrollPosition,
        },
      },
    };

    yield put({
      type: '@products/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        scrollbarPosition: scrollPosition,
        ...response.data.products,
      },
    });
  } catch (error: any) {
    if (error.status === 404) {
      const [errorMessage] = error.data.errorMessages;

      let message;
      if (sellerId && (situation || status || search)) {
        message = 'Nenhum resultado encontrado, verifique os dados inseridos e tente novamente.';
      } else if (!sellerId) {
        message = 'Nenhum seller foi selecionado.';
      } else {
        message = 'Não existem produtos integrados para listar.';
      }

      yield put({
        type: '@products/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          scrollbarPosition: scrollPosition,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: message,
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@products/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          scrollbarPosition: scrollPosition,
        },
      });
    }
  }
}

const callBlockSku = (skuId: string, reason: string) =>
  call(api.post, `${REACT_APP_CATALOG_API_URL}/skus/${skuId}/blocks`, {
    blockReason: reason,
  });

const callUnBlockSku = (skuId: string) => call(api.post, `${REACT_APP_CATALOG_API_URL}/skus/${skuId}/unblocks`);

function* unblockSku({ payload: { values, callback } }: FetchBlockSkusAction) {
  const { skus, skuId } = values;
  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Processando solicitação, isso pode demorar um pouco!',
      type: 'loading',
      open: true,
    },
  });
  try {
    if (skuId) {
      yield callUnBlockSku(skuId);
    }
    if (skus) {
      yield all(
        skus.map(({ id }) => {
          return callUnBlockSku(id);
        }),
      );
    }
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Desbloqueio realizado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (err) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao desbloquear o sku! Por favor tente novamente!',
        type: 'error',
        open: true,
      },
    });
  } finally {
    callback();
    yield put({
      type: '@products/FETCH',
      payload: {
        ...values,
        fetching: true,
      },
    });
  }
}

function* disapprovalReasons({ payload: { ids } }: FetchDisapprovalReasonsAction) {
  try {
    const { data } = yield call(
      api.get,
      `${REACT_APP_CATALOG_API_URL}/skus/disapproval-reasons?skuMarketplaceIds=${ids.toString()}`,
    );

    const { products } = yield select();

    const items = products.items.map((item: IPortalsProductBase) => {
      return {
        ...item,
        skus: item.skus.map((sku: ISKU) => {
          return {
            ...sku,
            disapprovalReasons: data.find((reason: IReason) => reason.skuMarketplaceId === sku.id)?.disapprovalReasons,
          };
        }),
      };
    });

    yield put({
      type: '@skus/DISAPPROVAL_REASONS_SUCCESS',
      payload: {
        items,
      },
    });
  } catch (err) {
    yield put({
      type: '@skus/DISAPPROVAL_REASONS_ERROR',
    });
  }
}

function* blockSku({ payload: { values, callback } }: FetchBlockSkusAction) {
  const { skus, reason, skuId, scrollPosition } = values;
  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Processando solicitação, isso pode demorar um pouco!',
      type: 'loading',
      open: true,
    },
  });
  try {
    if (skuId) {
      yield callBlockSku(skuId, reason);
    }
    if (skus) {
      yield all(
        skus.map(({ id }) => {
          return callBlockSku(id, reason);
        }),
      );
    }

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Bloqueio realizado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao bloquear um ou mais skus! Por favor tente novamente!',
        type: 'error',
        open: true,
      },
    });
  } finally {
    callback();
    yield put({
      type: '@products/FETCH',
      payload: {
        ...values,
        fetching: true,
        scrollPosition,
      },
    });
  }
}

function* updateProductsCatalog({ payload: { sellerId } }: FetchSellerAction) {
  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Processando solicitação, isso pode demorar um pouco. Não é necessário aguardar nesta página.',
      type: 'loading',
      open: true,
    },
  });
  try {
    yield call(api.post, `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/integrations/sync`);
  } catch (error: any) {
    if (error?.status === 409) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Essa solicitação já está em andamento.',
          type: 'warning',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao tentar enviar a solicitação!',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* forceProductUpdate({ payload: { skus, sellerId } }: FetchUpdateSkusAction) {
  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Processando solicitação, isso pode demorar um pouco. Não é necessário aguardar nesta página.',
      type: 'loading',
      open: true,
    },
  });
  try {
    yield all(
      skus.map(({ id }) =>
        call(api.post, `${REACT_APP_VTEX_NOTIFICATION_URL}/sellers/${sellerId}/skus/${id}/notification`),
      ),
    );
  } catch (error: any) {
    if (error?.status === 409) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Essa solicitação já está em andamento.',
          type: 'warning',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao tentar enviar a solicitação!',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* validateProductsCatalog({ payload: { sellerId } }: FetchSellerAction) {
  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Processando solicitação, isso pode demorar um pouco!',
      type: 'loading',
      open: true,
    },
  });

  try {
    yield call(api.post, `${REACT_APP_CATALOG_API_URL}/sellers/${sellerId}/validate`);
  } catch (error: any) {
    if (error?.status === 409) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Essa solicitação já está em andamento.',
          type: 'warning',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao tentar enviar a solicitação!',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* forceProductValidate({ payload: { skus } }: FetchValidateSkusAction) {
  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Processando solicitação, isso pode demorar um pouco. Não é necessário aguardar nesta página.',
      type: 'loading',
      open: true,
    },
  });

  try {
    yield all(skus.map(({ id }) => call(api.post, `${REACT_APP_CATALOG_API_URL}/skus/${id}/validate`)));
  } catch (error: any) {
    if (error?.status === 409) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Essa solicitação já está em andamento.',
          type: 'warning',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao tentar enviar a solicitação!',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* verifyBusinessParam({ payload: { sellerId } }: ProductsAction<{ sellerId: string }>) {
  try {
    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/IntegraCatalogoCorp/rules-exceptions?context-values=${sellerId}`,
    );

    const defaultValue = data.defaultValue === 'true';
    const isCatalogBlocked =
      data.isActive && defaultValue && data.ruleExceptions && data.ruleExceptions[0]?.value === 'false';

    yield put({
      type: '@products/VERIFY_BUSINESS_PARAM_SUCCESS',
      payload: { isCatalogBlocked },
    });
  } catch (error) {
    yield put({
      type: '@products/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        isCatalogBlocked: initialState.isCatalogBlocked,
      },
    });
  }
}

function* blockCatalog({ payload: { sellerId, reason } }: FetchBlockCatalogAction) {
  try {
    const currentRequest = reason ? 'blocks' : 'unblocks';
    yield call(api.post, `${REACT_APP_CATALOG_API_URL}/sellers/${sellerId}/${currentRequest}`, {
      reason,
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `${
          reason ? 'Bloqueio' : 'Desbloqueio'
        } massivo do catálogo solicitado com sucesso, isso pode levar um tempo.`,
        type: 'success',
        open: true,
      },
    });
    yield put({
      type: '@products/BLOCK_CATALOG_SUCCESS',
      payload: {
        fetchBlockCatalog: false,
      },
    });
  } catch (error: any) {
    yield put({
      type: '@products/BLOCK_CATALOG_ERROR',
      payload: {
        fetchBlockCatalog: false,
      },
    });
    if (error?.status === 409) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Essa solicitação já está em andamento.',
          type: 'warning',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* saga() {
  yield takeLatest('@products/FETCH', fetchPortalsProducts);
  yield takeLatest('@products/VERIFY_BUSINESS_PARAM', verifyBusinessParam);
  yield takeLatest('@products/BLOCK_CATALOG', blockCatalog);
  yield takeLatest('@skus/DISAPPROVAL_REASONS', disapprovalReasons);
  yield takeLatest('@skus/BLOCK', blockSku);
  yield takeLatest('@skus/UNBLOCK', unblockSku);
  yield takeEvery('@skus/VERIFY_URL', verifyUrl);
  yield takeEvery('@skus/UPDATE_CATALOG', updateProductsCatalog);
  yield takeEvery('@skus/FORCE_PRODUCT_UPDATE', forceProductUpdate);
  yield takeEvery('@skus/VALIDATE_CATALOG', validateProductsCatalog);
  yield takeEvery('@skus/FORCE_PRODUCT_VALIDATE', forceProductValidate);
}

export { reducer as productsReducer, saga as productsSaga };
