import { Reducer } from 'react';
import { Color } from '@material-ui/lab/Alert';
import { LayoutState } from '../../../@types/layout';

type LayoutActionsType = {
  type: string;
  payload: {
    message: string;
    type: Color;
    fullName: string;
    open: boolean;
    menuOpen?: false;
  };
};

const initialState: LayoutState = {
  message: '',
  type: 'success',
  fullName: '',
  open: false,
  menuOpen: sessionStorage.getItem('@rchlo/admin/menuOpen') === 'false' ? false : true,
};

const layoutReducer: Reducer<LayoutState, LayoutActionsType> = (state = initialState, { payload, type }) => {
  switch (type) {
    case '@layout/FETCH_SNACKBAR_MESSAGE':
      return {
        ...state,
        ...payload,
      };
    case '@layout/MENU_CLICK':
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    default:
      return state;
  }
};

export { layoutReducer };
