import { Reducer } from 'react';
import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { IBrands, BrandsRequest } from '../../../@types/brands.d';
import type { ErrorMessages, Pagination } from '../../../@types/index.d';
import { api } from '../../../services/api';

const API_CORE = process.env.REACT_APP_CORE_API_URL;

export type BrandsState = Pagination<IBrands> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  search?: string;
};

export type BrandsAction<T> = Action<string> & {
  payload: T;
};

export type FetchBrandsAction = BrandsAction<BrandsRequest>;

const initialState: BrandsState = {
  fetching: false,
  success: false,
  errorMessages: [],
  items: [],
  pageIndex: 0,
  pageSize: 20,
  totalCount: 0,
  totalPages: 0,
  indexFrom: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

const brandsReducer: Reducer<BrandsState, FetchBrandsAction | any> = (state = initialState, { payload, type }) => {
  switch (type) {
    case '@brands/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@brands/FETCH_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@brands/FETCH_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@brands/CLEAN': {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

function* fetchBrands({ payload: { pageIndex = 0, pageSize = 20, search } }: FetchBrandsAction) {
  try {
    const response = yield call(api.get, `${API_CORE}/brands-not-allowed`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
        search,
      },
    });

    yield put({
      type: '@brands/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        ...response?.data,
      },
    });
  } catch (error: any) {
    if (error.status === 404 && error.data.errorMessages) {
      const [errorMessage] = error.data.errorMessages;
      yield put({
        type: '@brands/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: 'Não existem informações para listar.',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@brands/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              notFoundHelperText: 'Não existem informações para listar.',
            },
          ],
        },
      });
    }
  }
}

function* brandsSaga() {
  yield takeLatest('@brands/FETCH', fetchBrands);
}

export { brandsReducer, brandsSaga };
