import normalizeProperty from './normalizeProperty';
import toKebab from './toKebab';

const normalizeParam = ([key, value]: any[]) => [toKebab(key), normalizeProperty(value)];

const normalizeParams = (params: any): any | undefined => {
  if (!params) return undefined;

  const normalizeParams: any = {};

  Object.entries<any>(params).forEach((entrie) => {
    const [key, value] = normalizeParam(entrie);
    normalizeParams[key] = value;
  });

  return normalizeParams;
};

export default normalizeParams;
