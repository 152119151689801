import React from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

interface Props {
  message: string;
  handleClose: Function;
}

const LoadingAlert: React.FC<Props> = ({ message, handleClose }) => {
  return (
    <div
      style={{
        width: '572px',
        backgroundColor: '#F4F4F4',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        padding: '10px',
      }}
    >
      <CircularProgress size={16} color="inherit" />
      <span style={{ color: '#252525', marginLeft: '18px' }}>{message}</span>
      <div style={{ flex: 1 }} />
      <IconButton data-testid="buttonClose" style={{ height: '20px', width: '20px' }} onClick={() => handleClose()}>
        <Close fontSize="small" style={{ color: '#808080' }} />
      </IconButton>
    </div>
  );
};

export default LoadingAlert;
