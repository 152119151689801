import { AuthClientTokens, AuthClientEvent } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { KeycloakInitOptions, KeycloakInstance } from 'keycloak-js';
import React, { PropsWithChildren, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../store';
import Communication from '../Communication';

const clearSessionStorage = () => {
  sessionStorage.removeItem('@rchlo/admin/access_token');
};

const handleOnTokens = ({ token }: AuthClientTokens) => {
  if (token) {
    sessionStorage.setItem('@rchlo/admin/access_token', token);
  }
};

const defaultInitOptions: KeycloakInitOptions = {
  checkLoginIframe: false,
};

const Root: React.FC<
  PropsWithChildren<{
    authClient: KeycloakInstance;
    initOptions?: KeycloakInitOptions;
  }>
> = ({ authClient, children, initOptions = defaultInitOptions }) => {
  const [{ onAuthLogout, onReady }, dispatchEvent] = useState<{ [key: string]: boolean }>({
    onReady: false,
    onAuthLogout: false,
  });

  const handleOnEvent =
    (dispatch: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>) => (eventType: AuthClientEvent) => {
      switch (eventType) {
        case 'onTokenExpired':
        case 'onAuthRefreshSuccess':
        case 'onAuthSuccess':
        case 'onReady':
          dispatch({ onReady: true });
          break;
        case 'onInitError':
        case 'onAuthRefreshError':
        case 'onAuthError':
        case 'onAuthLogout':
        default:
          clearSessionStorage();
          dispatch({ onAuthLogout: true });
          authClient.clearToken();
          authClient.logout();
          break;
      }
    };

  return (
    <ReactKeycloakProvider
      authClient={authClient}
      onTokens={handleOnTokens}
      initOptions={initOptions}
      onEvent={handleOnEvent(dispatchEvent)}
    >
      <Provider store={store}>
        {children}
        <Communication />
      </Provider>
    </ReactKeycloakProvider>
  );
};

export default Root;
