import { useKeycloak } from '@react-keycloak/web';
import React, { PropsWithChildren, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Layout as LayoutStorybook } from '@rchlo/storybook/core';
import { StorybookContext } from '../contexts/Storybook.context';
import { allMenuOptions, SubMenuProps } from '../shared/components/Menu/MenuOptions';
import verifyPermission from '../shared/utils/verifyPermission';

const theme = {
  header: {
    bg: '#ED6926',
    color: 'white',
    colorHover: '#BE541E',
  },
  sidemenu: {
    bg: 'white',
    avatar: {
      color: '#252525',
    },
    accordion: {
      color: '#707070',
      bgHover: '#F4F4F4',
      bgActive: '#ED6926',
      colorHover: '#444',
      colorActive: 'white',
    },
  },
  main: {
    bg: '#f4f4f4',
  },
};

const preloadPages = (submenu: SubMenuProps[]) => {
  submenu?.forEach(({ preloadPage }) => preloadPage && import(`../pages/${preloadPage}`));
};

const Layout: React.FC<PropsWithChildren<{ style?: {}; className?: string }>> = ({
  children,
  style,
  className = 'admin',
}) => {
  const { keycloak } = useKeycloak();

  const { resourceAccess, tokenParsed } = keycloak;

  const history = useHistory();
  const dispatch = useDispatch();

  const cbAction = (action: string, submenu?: SubMenuProps[]) => {
    switch (action) {
      case 'logout':
        keycloak.logout({ redirectUri: `${window.location.origin}/` });
        break;
      case 'preload_pages':
        preloadPages(submenu || []);
        break;
      case 'menuCollapseButton':
        dispatch({
          type: '@layout/MENU_CLICK',
        });
        break;
    }
  };

  const sideMenuOptions = allMenuOptions.filter((menuOption) => {
    if (!verifyPermission(menuOption.requiredUserPermission, resourceAccess)) {
      return false;
    }

    menuOption.submenu = menuOption.submenu?.filter((optSubmenu) =>
      verifyPermission(optSubmenu.requiredUserPermission, resourceAccess),
    );

    return true;
  });

  return (
    <LayoutStorybook
      style={style}
      className={className}
      tokenParsed={tokenParsed!}
      cbAction={cbAction}
      menuOptions={sideMenuOptions}
      history={history}
      theme={theme}
    >
      {children}
    </LayoutStorybook>
  );
};

const useLayout = () => {
  const context = useContext(StorybookContext);
  const { keycloak } = useKeycloak();
  const history = useHistory();

  if (!context?.menu) {
    throw new Error('Without context!');
  }

  const { resourceAccess, tokenParsed } = keycloak;

  const logoutUser = () => {
    keycloak.logout({ redirectUri: `${window.location.origin}/` });
  };

  return { menu: context.menu, history, logoutUser, Layout, resourceAccess, tokenParsed };
};

export default useLayout;
