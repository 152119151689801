import type { Reducer, Action } from 'redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import { store } from '../..';
import {
  DeliveryFailuresAction,
  DeliveryFailuresRequest,
  DeliveryFailuresState,
  FetchListDeliveryFailuresAction,
  ToggleDeliveryFailuresStatusAction,
} from '../../../@types/deliveryFailures';
import { api } from '../../../services/api';

const { REACT_APP_ORDER_SALE_API_URL } = process.env;

const initialState: DeliveryFailuresState = {
  fetching: false,
  success: false,
  errorMessages: [],
  items: [],
  pageIndex: 0,
  pageSize: 8,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  submitting: false,
};

const reducer: Reducer<DeliveryFailuresState, DeliveryFailuresAction<any>> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@deliveryFailures/FETCH_LIST':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@deliveryFailures/FETCH_LIST_SUCCESS':
    case '@deliveryFailures/FETCH_LIST_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: false,
      };
    case '@deliveryFailures/TOGGLE_STATUS_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@deliveryFailures/ADD_SUCCESS':
    case '@deliveryFailures/UPDATE_SUCCESS':
      return {
        ...state,
        success: true,
        submitting: false,
      };
    case '@deliveryFailures/ADD_ERROR':
    case '@deliveryFailures/UPDATE_ERROR':
      return {
        ...state,
        success: false,
        submitting: false,
      };
    case '@deliveryFailures/FORM_CLEAN':
      return {
        ...state,
        submitting: false,
      };
    case '@deliveryFailures/FORM_SUBMITTING':
      return {
        ...state,
        submitting: true,
      };
    default:
      return state;
  }
};

export type DeliveryFailureAction<T> = Action<string> & {
  payload: T;
};

export type AddDeliveryFailuresAction = DeliveryFailureAction<DeliveryFailuresRequest>;

function* fetchDeliveryFailuresList({ payload: { pageIndex = 0, pageSize = 8 } }: FetchListDeliveryFailuresAction) {
  const params = {
    pageIndex,
    pageSize,
  };

  try {
    const response = yield call(api.get, `${REACT_APP_ORDER_SALE_API_URL}/deliveries-failure`, { params });

    yield put({
      type: '@deliveryFailures/FETCH_LIST_SUCCESS',
      payload: {
        success: response.success,
        ...response.data,
      },
    });
  } catch (error: any) {
    const notFoundHelperText = 'Não existem informações para listar.';

    if (error.status === 404 && error.data.errorMessages) {
      const [errorMessage] = error.data.errorMessages;
      yield put({
        type: '@deliveryFailures/FETCH_LIST_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText,
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@deliveryFailures/FETCH_LIST_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              notFoundHelperText,
            },
          ],
        },
      });
    }
  }
}

function* addDeliveryFailure({ payload }: AddDeliveryFailuresAction) {
  yield put({ type: '@deliveryFailures/FORM_SUBMITTING' });

  try {
    const deliveryFailureResponse = yield call(
      api.post,
      `${REACT_APP_ORDER_SALE_API_URL}/deliveries-failure/reason`,
      payload,
    );

    if (deliveryFailureResponse) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Motivo adicionado com sucesso!',
          type: 'success',
          open: true,
        },
      });

      yield put({
        type: '@deliveryFailures/ADD_SUCCESS',
        payload: deliveryFailureResponse,
      });
    } else {
      yield put({
        type: '@deliveryFailures/ADD_ERROR',
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@deliveryFailures/ADD_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* updateDeliveryFailure({ payload }: AddDeliveryFailuresAction) {
  yield put({ type: '@deliveryFailures/FORM_SUBMITTING' });

  try {
    const deliveryFailureResponse = yield call(
      api.patch,
      `${REACT_APP_ORDER_SALE_API_URL}/deliveries-failure/${payload.id}`,
      payload,
    );

    if (deliveryFailureResponse) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Alterações salvas com sucesso!',
          type: 'success',
          open: true,
        },
      });

      yield put({
        type: '@deliveryFailures/UPDATE_SUCCESS',
        payload: deliveryFailureResponse,
      });
    } else {
      yield put({
        type: '@deliveryFailures/UPDATE_ERROR',
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@deliveryFailures/UPDATE_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* toggleDeliveryFailuresStatus({ payload }: ToggleDeliveryFailuresStatusAction) {
  const { status } = payload;
  const {
    deliveryFailures: { items },
  } = store.getState();

  try {
    yield call(api.patch, `${REACT_APP_ORDER_SALE_API_URL}/deliveries-failure/${payload.id}`, { status });

    yield put({
      type: '@deliveryFailures/TOGGLE_STATUS_SUCCESS',
      payload: {
        items: items.map((deliveryFailure: { id: string; status: boolean }) => {
          if (deliveryFailure.id === payload.id) {
            deliveryFailure.status = status;
          }
          return deliveryFailure;
        }),
      },
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Motivo ${status ? 'ativado' : 'inativado'} com sucesso!`,
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* saga() {
  yield takeEvery('@deliveryFailures/FETCH_LIST', fetchDeliveryFailuresList);
  yield takeEvery('@deliveryFailures/TOGGLE_STATUS', toggleDeliveryFailuresStatus);
  yield takeEvery('@deliveryFailures/ADD', addDeliveryFailure);
  yield takeEvery('@deliveryFailures/UPDATE', updateDeliveryFailure);
}

export { reducer as deliveryFailuresReducer, saga as deliveryFailuresSaga };
