import { Reducer } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import isValidFilter from '@rchlo/storybook/core/shared/utils/isValidFilter';
import { store } from '../..';
import type {
  CreatePlatformAction,
  FetchPlatformAction,
  FetchPlatformsAction,
  PlatformsState,
  TogglePlatformStatusAction,
  UpdatePlatformAction,
} from '../../../@types/platforms.d';
import { api } from '../../../services/api';

const initialState: PlatformsState = {
  fetching: false,
  success: false,
  errorMessages: [],
  items: [],
  pageIndex: 0,
  pageSize: 8,
  totalCount: 0,
  totalPages: 0,
  indexFrom: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  isSubmitting: false,
};

const platformsReducer: Reducer<PlatformsState, FetchPlatformsAction | any> = (
  state = initialState,
  { payload, type },
) => {
  switch (type) {
    case '@platforms/CLEAN':
      return {
        ...state,
        ...payload,
        fetching: false,
        isSubmitting: false,
        success: false,
        formData: undefined,
      };
    case '@platforms/UPDATE_INTEGRATION_PLATFORM':
    case '@platforms/CREATE':
      return {
        ...state,
        ...payload,
        fetching: true,
        isSubmitting: true,
      };
    case '@platforms/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@platforms/CREATE_ERROR':
    case '@platforms/CREATE_SUCCESS':
    case '@platforms/UPDATE_INTEGRATION_PLATFORM_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        isSubmitting: false,
      };
    case '@platforms/TOGGLE_INTEGRATION_PLATFORM_STATUS_SUCCESS':
    case '@platforms/UPDATE_INTEGRATION_PLATFORM_SUCCESS':
    case '@platforms/FETCH_INTEGRATION_PLATFORM_SUCCESS':
    case '@platforms/FETCH_SUCCESS':
    case '@platforms/FETCH_ERROR':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

function* fetchPlatforms({ payload: { pageIndex = 0, pageSize = 20, isActive } }: FetchPlatformsAction) {
  const isFilter = isValidFilter({
    isActive,
  });

  try {
    const response = yield call(api.get, `${process.env.REACT_APP_SELLER_API_URL}/integration-platforms`, {
      params: {
        pageSize,
        pageIndex,
        'is-active': isActive,
      },
    });

    yield put({
      type: '@platforms/FETCH_SUCCESS',
      payload: {
        errorMessages: response.errorMessages,
        fetching: false,
        ...response?.data,
      },
    });
  } catch (error: any) {
    if (error.status === 404) {
      const [errorMessage] = error.data.errorMessages;
      yield put({
        type: '@platforms/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: !isFilter
                ? 'Não existe plataformas para listar.'
                : 'Nenhum resultado encontrado, verifique os dados inseridos e tente novamente.',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@platforms/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
        },
      });
    }
  }
}

function* createIntegrationPlatform({ payload }: CreatePlatformAction) {
  try {
    const response = yield call(api.post, `${process.env.REACT_APP_SELLER_API_URL}/integration-platforms`, payload);

    yield put({
      type: '@platforms/CREATE_SUCCESS',
      payload: {
        success: response.success,
        ...response?.data,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Plataforma cadastrada com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error: any) {
    yield put({
      type: '@platforms/CREATE_ERROR',
      payload: {
        ...initialState,
        ...error.data,
      },
    });
    if (error?.status === 409) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Plataforma já cadastrada.',
          type: 'warning',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* fetchIntegrationPlatform({ payload }: FetchPlatformAction) {
  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_SELLER_API_URL}/integration-platforms/${payload.platformId}`,
    );

    yield put({
      type: '@platforms/FETCH_INTEGRATION_PLATFORM_SUCCESS',
      payload: {
        formData: {
          id: response?.data?.id,
          name: response?.data?.name,
          contacts: response?.data?.contacts,
        },
      },
    });
  } catch (error: any) {
    yield put({
      type: '@platforms/FETCH_INTEGRATION_PLATFORM_ERROR',
      payload: {
        ...initialState,
        ...error.data,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* updateIntegrationPlatform({ payload }: UpdatePlatformAction) {
  try {
    yield call(api.put, `${process.env.REACT_APP_SELLER_API_URL}/integration-platforms/${payload.platformId}`, payload);
    yield put({
      type: '@platforms/UPDATE_INTEGRATION_PLATFORM_SUCCESS',
      payload: {
        success: true,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Alterações salvas com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error: any) {
    yield put({
      type: '@platforms/UPDATE_INTEGRATION_PLATFORM_ERROR',
      payload: {
        ...initialState,
        ...error.data,
      },
    });
    if (error?.status === 409) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Plataforma já cadastrada.',
          type: 'warning',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* togglePlatformStatus({ payload }: TogglePlatformStatusAction) {
  const { isActive } = payload;
  const {
    platforms: { items },
  } = store.getState();
  try {
    yield call(api.patch, `${process.env.REACT_APP_SELLER_API_URL}/integration-platforms/${payload.platformId}`, {
      isActive,
    });
    yield put({
      type: '@platforms/TOGGLE_INTEGRATION_PLATFORM_STATUS_SUCCESS',
      payload: {
        items: items.map((platform: { id: string; isActive: boolean }) => {
          if (platform.id === payload.platformId) {
            platform.isActive = isActive;
          }
          return platform;
        }),
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Plataforma ${isActive ? 'ativada' : 'inativada'} com sucesso!`,
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* platformsSaga() {
  yield takeLatest('@platforms/FETCH', fetchPlatforms);
  yield takeLatest('@platforms/CREATE', createIntegrationPlatform);
  yield takeLatest('@platforms/FETCH_INTEGRATION_PLATFORM_DETAILS', fetchIntegrationPlatform);
  yield takeLatest('@platforms/UPDATE_INTEGRATION_PLATFORM', updateIntegrationPlatform);
  yield takeLatest('@platforms/TOGGLE_INTEGRATION_PLATFORM_STATUS', togglePlatformStatus);
}

export { platformsReducer, platformsSaga };
