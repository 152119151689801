import DashboardIcon from '@material-ui/icons/Dashboard';
import Settings from '@material-ui/icons/Settings';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

const options = [
  {
    label: 'Dashboard',
    url: '/',
    icon: DashboardIcon,
  },
  {
    label: 'Produtos',
    url: '/products',
    icon: ShoppingBasketIcon,
  },
  {
    label: 'Configurações',
    url: '/settings',
    icon: Settings,
  },
];

export { options };
