import { Reducer } from 'react';
import type { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { DefaultRequest, ErrorMessages, Pagination } from '../../../@types';
import type { Group } from '../../../@types/groups';
import { api } from '../../../services/api';

const API_SERVICE = `${process.env.REACT_APP_USER_MANAGEMENT_API_URL}`;

export type GroupsState = Pagination<Group> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
};

export type GroupsAction<T> = Action<string> & {
  payload: T;
};

export type ListGroupsAction = GroupsAction<DefaultRequest>;

const initialState: GroupsState = {
  fetching: false,
  success: false,
  items: [],
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
};
const groupsReducer: Reducer<GroupsState, GroupsAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@groups/FETCH_SUCCESS':
      return {
        ...state,
        success: payload.success,
        errorMessages: payload.errorMessages,
        ...payload.data,
        fetching: false,
      };
    case '@groups/FETCH_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* listGroups({ payload: { pageIndex = 0, pageSize = 20, retries = 2 } }: ListGroupsAction) {
  try {
    const responsePayload = yield call(api.get, `${API_SERVICE}/groups`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
      },
    });
    if (responsePayload) {
      yield put({
        type: '@groups/FETCH_SUCCESS',
        payload: responsePayload,
      });
    } else {
      throw new Error(
        JSON.stringify({
          success: false,
          errorMessages: [
            {
              message: 'Operation not effected. Users is not registered.',
              notFoundHelperText: 'Não existem grupos para listar.',
            },
          ],
          items: [],
          returnMessageType: 'ClientErrorNotFound',
        }),
      );
    }
  } catch (error) {
    if (retries !== 0) {
      yield put({
        type: '@groups/FETCH',
        payload: {
          pageIndex: 0,
          pageSize: 20,
          retries: retries - 1,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao listar os níveis de acesso. Tente novamente mais tarde.',
          type: 'error',
          open: true,
        },
      });
    }
    yield put({
      type: '@groups/FETCH_ERROR',
      payload: {
        errorMessages: [
          {
            message: 'Erro ao acessar os níveis de acesso',
          },
        ],
      },
    });
  }
}

function* groupsSaga() {
  yield takeLatest('@groups/FETCH', listGroups);
}

export { groupsReducer, groupsSaga };
