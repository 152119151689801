import type { Action, Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DefaultRequest, ErrorMessages, Pagination } from '../../../@types';
import { ChannelOption, ChannelResponse } from '../../../@types/products';
import { api } from '../../../services/api';
import { store } from '../../index';

const { REACT_APP_CATALOG_API_URL } = process.env;

export type ChannelState = Pagination<ChannelOption> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
};

export type ChannelAction<T> = Action<string> & {
  payload: T;
};

export type FetchChannelsAction = ChannelAction<DefaultRequest>;

const initialState: ChannelState = {
  fetching: false,
  success: false,
  items: [
    { id: '', name: 'Todas' },
    { id: 'Riachuelo', name: 'Riachuelo' },
  ],
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
};

const reducer: Reducer<ChannelState, ChannelAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@productsChannel/FETCH_CHANNELS_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@productsChannel/FETCH_CHANNELS_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

function* fetchChannels({ payload: { pageIndex = 0, pageSize = 8 } }: FetchChannelsAction) {
  const {
    channel: { items },
  } = store.getState();

  try {
    const response: ChannelResponse = yield call(api.get, `${REACT_APP_CATALOG_API_URL}/channels`, {
      params: {
        pageSize,
        pageIndex,
      },
    });

    yield put({
      type: '@productsChannel/FETCH_CHANNELS_SUCCESS',
      payload: {
        items: items.concat(response.data.items),
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
      },
    });
  } catch (error: any) {
    yield put({
      type: '@productsChannel/FETCH_CHANNELS_ERROR',
      payload: {
        ...initialState,
        ...error.data,
      },
    });
  }
}

function* saga() {
  yield takeLatest('@productsChannel/FETCH_CHANNELS', fetchChannels);
}

export { reducer as channelReducer, saga as channelSaga };
