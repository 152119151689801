import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const goTo = (path: string, state?: unknown): boolean => {
  history.push(path, state);
  return true;
};

export default history;
