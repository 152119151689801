import React, { lazy } from 'react';
import { UserPermission } from '../@types/auth';

const importPageByPath = (path: string) => lazy(async () => await import(`./${path}`));
/*

  @publicAccess - O acesso às rotas, por padrão, é sempre restrita! Caso queira deixar alguma visualização de página pública, basta adicionar a propriedade publicAccess como true.

  @requiredUserPermissions - É obrigatório a informação do campo requiredUserPermissions para o sistema verificar a role que o usuário precisa possuir para acessar a rota (aqui utilizamos a role que vem no resourceAccess dele). Caso ele não precise ter nenhuma role específica, veja a flag a seguir.

  @noExtraPermissionNeeded - Caso você não precise verificar as roles do usuário para liberar o acesso à rota, deixe essa flag como true.

 */

const {
  REACT_APP_FEATURE_TOGGLE_COMMUNICATION_VIEW,
  REACT_APP_FEATURE_TOGGLE_COMMUNICATION_MANAGE,
  REACT_APP_FEATURE_TOOGLE_TERMS,
  REACT_APP_FEATURE_TOGGLE_BRANDS,
  REACT_APP_FEATURE_TOGGLE_PRODUCT_VIEW,
  REACT_APP_FEATURE_TOGGLE_SELLER_VIEW,
  REACT_APP_FEATURE_TOGGLE_SELLER_MANAGE,
  REACT_APP_FEATURE_TOGGLE_USER_VIEW,
  REACT_APP_FEATURE_TOGGLE_USER_MANAGE,
  REACT_APP_FEATURE_TOGGLE_SELLER_LITE_VIEW,
  REACT_APP_FEATURE_TOGGLE_SELLER_LITE_MANAGE,
  REACT_APP_FEATURE_TOGGLE_WEBHOOK_MANAGE,
  REACT_APP_FEATURE_TOGGLE_INTEGRATION_PLATFORM_MANAGE,
  REACT_APP_FEATURE_TOGGLE_FANLAB,
  REACT_APP_FEATURE_TOGGLE_DELIVERY_FAILURE_MANAGE,
} = process.env;
interface Route {
  path: string;
  component: React.FC;
  exact?: boolean;
  publicAccess?: boolean;
  requiredUserPermissions: UserPermission;
}

const routes: Route[] = [
  {
    path: '/sellers',
    component: importPageByPath('Sellers'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_VIEW,
    },
    exact: true,
  },
  {
    path: '/sellers/cadastro',
    component: importPageByPath('Sellers/SellerTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_MANAGE,
    },
    exact: true,
  },
  {
    path: '/seller/cadastro/:sellerId/juridico',
    component: importPageByPath('Sellers/SellerTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_MANAGE,
    },
    exact: true,
  },
  {
    path: '/seller/:sellerId/editar/juridico',
    component: importPageByPath('Sellers/SellerTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_MANAGE,
    },
    exact: true,
  },
  {
    path: '/seller/:sellerId/editar',
    component: importPageByPath('Sellers/SellerTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_MANAGE,
    },
    exact: true,
  },
  {
    path: '/seller/:sellerId/detalhes',
    component: importPageByPath('Sellers/SellerTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_VIEW,
    },
    exact: true,
  },
  {
    path: '/conta-lite',
    component: importPageByPath('SellersLite'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_LITE_VIEW,
    },
    exact: true,
  },
  {
    path: '/conta-lite/cadastrar',
    component: importPageByPath('SellersLite/SellerLiteTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_LITE_MANAGE,
    },
    exact: true,
  },
  {
    path: '/produtos',
    component: importPageByPath('Products/Sellers'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-catalog',
      role: REACT_APP_FEATURE_TOGGLE_PRODUCT_VIEW,
    },
    exact: true,
  },
  {
    path: '/produtos/catalogo/:id',
    component: importPageByPath('Products/Product'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-catalog',
      role: REACT_APP_FEATURE_TOGGLE_PRODUCT_VIEW,
    },
  },
  {
    path: '/produtos/sku/:id',
    component: importPageByPath('Products/Sku'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-catalog',
      role: REACT_APP_FEATURE_TOGGLE_PRODUCT_VIEW,
    },
  },
  {
    path: '/produtos/catalogo',
    component: importPageByPath('Products/Products'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-catalog',
      role: REACT_APP_FEATURE_TOGGLE_PRODUCT_VIEW,
    },
    exact: true,
  },
  {
    path: '/fanlab/gestao-de-produtos',
    component: importPageByPath('Fanlab/Products'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-catalog',
      role: REACT_APP_FEATURE_TOGGLE_FANLAB,
    },
    exact: true,
  },
  {
    path: '/pedidos/insucesso-na-entrega',
    component: importPageByPath('DeliveryFailures/DeliveryFailures'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-order-sale',
      role: REACT_APP_FEATURE_TOGGLE_DELIVERY_FAILURE_MANAGE,
    },
    exact: true,
  },
  {
    path: '/pedidos/insucesso-na-entrega/cadastro',
    component: importPageByPath('DeliveryFailures/DeliveryFailuresAdd'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-order-sale',
      role: REACT_APP_FEATURE_TOGGLE_DELIVERY_FAILURE_MANAGE,
    },
    exact: true,
  },
  {
    path: '/pedidos/insucesso-na-entrega/:deliveryFailureId/editar',
    component: importPageByPath('DeliveryFailures/DeliveryFailuresEdit'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-order-sale',
      role: REACT_APP_FEATURE_TOGGLE_DELIVERY_FAILURE_MANAGE,
    },
    exact: true,
  },
  {
    path: '/usuarios/:sellerId',
    component: importPageByPath('Users/Users'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-user-management',
      role: REACT_APP_FEATURE_TOGGLE_USER_VIEW,
    },
  },
  {
    path: '/usuario/adicionar/:sellerId',
    component: importPageByPath('Users/UserAdd'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-user-management',
      role: REACT_APP_FEATURE_TOGGLE_USER_MANAGE,
    },
  },
  {
    path: '/usuario/editar/:sellerId/:id',
    component: importPageByPath('Users/UserEdit'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-user-management',
      role: REACT_APP_FEATURE_TOGGLE_USER_MANAGE,
    },
  },
  {
    path: '/blocklist/termos',
    component: importPageByPath('Blocklist/Terms'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-core',
      role: REACT_APP_FEATURE_TOOGLE_TERMS,
    },
  },
  {
    path: '/blocklist/marcas',
    component: importPageByPath('Blocklist/Brands'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-core',
      role: REACT_APP_FEATURE_TOGGLE_BRANDS,
    },
  },
  {
    path: '/central-de-comunicados',
    component: importPageByPath('Communications/Management'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-notification',
      role: REACT_APP_FEATURE_TOGGLE_COMMUNICATION_VIEW,
    },
    exact: true,
  },
  {
    path: '/central-de-comunicados/cadastrar',
    component: importPageByPath('Communications/Communication'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-notification',
      role: REACT_APP_FEATURE_TOGGLE_COMMUNICATION_MANAGE,
    },
    exact: true,
  },
  {
    path: '/central-de-comunicados/:id/editar',
    component: importPageByPath('Communications/Communication'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-notification',
      role: REACT_APP_FEATURE_TOGGLE_COMMUNICATION_MANAGE,
    },
    exact: true,
  },
  {
    path: '/central-de-comunicados/:id',
    component: importPageByPath('Communications/Communication'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-notification',
      role: REACT_APP_FEATURE_TOGGLE_COMMUNICATION_VIEW,
    },
    exact: true,
  },
  {
    path: '/webhook/:sellerId',
    component: importPageByPath('Webhook/Webhook'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_VIEW,
    },
    exact: true,
  },
  {
    path: '/webhook/:sellerId/cadastrar',
    component: importPageByPath('Webhook/WebhookAdd'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_WEBHOOK_MANAGE,
    },
    exact: true,
  },
  {
    path: '/webhook/:sellerId/editar',
    component: importPageByPath('Webhook/WebhookEdit'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_WEBHOOK_MANAGE,
    },
    exact: true,
  },
  {
    path: '/plataformas',
    component: importPageByPath('Platforms/Management'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_INTEGRATION_PLATFORM_MANAGE,
    },
    exact: true,
  },
  {
    path: '/plataformas/cadastrar',
    component: importPageByPath('Platforms/ManagementTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_INTEGRATION_PLATFORM_MANAGE,
    },
    exact: true,
  },
  {
    path: '/plataformas/:id/editar',
    component: importPageByPath('Platforms/ManagementTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_INTEGRATION_PLATFORM_MANAGE,
    },
    exact: true,
  },
  {
    path: '/plataformas/:id/detalhes',
    component: importPageByPath('Platforms/ManagementTabs'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_INTEGRATION_PLATFORM_MANAGE,
    },
    exact: true,
  },
  {
    path: '/acesso-negado',
    component: importPageByPath('Page403'),
    requiredUserPermissions: { noExtraPermissionNeeded: true },
    publicAccess: true,
  },
  {
    path: '/error',
    component: importPageByPath('Page500'),
    requiredUserPermissions: { noExtraPermissionNeeded: true },
    publicAccess: true,
  },
  {
    path: '/',
    component: importPageByPath('Sellers'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_VIEW,
    },
    exact: true,
  },
  {
    path: '*',
    component: importPageByPath('Page404'),
    requiredUserPermissions: { noExtraPermissionNeeded: true },
    publicAccess: true,
  },
];

export default routes;
