import moment from 'moment';
import { Reducer } from 'react';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { PlatformFetchListAction } from '../../../@types/platforms';
import {
  CreateSellerAction,
  CreateLegalAction,
  DeleteLegalAction,
  IUser,
  SellerAction,
  SellerState,
  CreateCepAction,
  SendFileAction,
  IErrorMessage,
  UpdateCommissionAction,
  ISeller,
  FetchCampaignsFileAction,
  ISkuCommissionItem,
  CommissionPayload,
  DeleteCampaignAction,
  FetchSellerPlatformNameAction,
  SellerPlatformRequestAction,
  ILegalRepresentative,
  CreateAntecipationComission,
  FetchCommissionCamapaignHistory,
} from '../../../@types/sellers';
import { initialValues } from '../../../pages/Sellers/utils/initialValues';
import { api } from '../../../services/api';
import { store } from '../../index';

const {
  REACT_APP_COMMISSION_API_URL,
  REACT_APP_SELLER_API_URL,
  REACT_APP_CORE_API_URL,
  REACT_APP_COMMISSION_API_URL_V2,
} = process.env;

const API_SERVICE = REACT_APP_SELLER_API_URL;
const CORE_SERVICE = REACT_APP_CORE_API_URL;

const initialState = {
  sellerId: '',
  isActive: false,
  success: false,
  name: '',
  errorMessages: [],
  fetching: false,
  isSubmitting: false,
  submitted: false,
  items: [],
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  pageIndex: 0,
  pageSize: 10,
  hasNextPage: false,
  hasPreviousPage: false,
  formikFormInputValues: {},
  sellerAddress: {},
  fetchingCep: false,
  acceptanceTermFile: {
    fileKey: '',
    loadingFile: false,
    hasError: false,
  },
  generalConditionGuidelinesFile: {
    fileKey: '',
    loadingFile: false,
    hasError: false,
  },
  attendancePolicyAftermarketFile: {
    fileKey: '',
    loadingFile: false,
    hasError: false,
  },
  serviceLevelAgreementFile: {
    fileKey: '',
    loadingFile: false,
    hasError: false,
  },
  legalRepresentativeSaveSuccess: false,
  sellerLegalRepresentatives: {
    fetching: false,
    success: false,
    items: [],
    indexFrom: 0,
    totalCount: 0,
    totalPages: 0,
    pageIndex: 0,
    pageSize: 10,
    hasNextPage: false,
    hasPreviousPage: false,
    errorMessages: [],
  },
  commissionUpdateSuccess: false,
  commission: {},
  loading: false,
  fileUrl: '',
  platformName: '',
  selectedPlatform: {},
  antecipationComission: null,
  sellerCommission: {
    id: '',
    commissionDefault: true,
    commissionBusiness: false,
    commissionBusinessValue: '',
    commissionDefaultValue: '',
    hasAntecipation: true,
    responsible: '',
  },
  commissionYearsHistory: [],
  campaignYears: {},
  loadingCampaigns: false,
};

type UpdateSellerAction = SellerAction<ISeller>;
type CommissionListAction = SellerAction<CommissionPayload>;

const sellerReducer: Reducer<SellerState, SellerAction<any>> = (state = initialState, { payload, type }) => {
  const campaignFetchingHandle = (items: Array<ISkuCommissionItem>, campaignId: string, showLoading: boolean) => {
    const campaigns = items.map((item) => {
      if (item.id === campaignId) {
        item.fetching = showLoading;
      }
      return item;
    });
    return campaigns;
  };

  switch (type) {
    case '@seller/CLEAN':
      return {
        ...initialValues,
        sellerAddress: {},
        formikFormInputValues: { ...initialValues },
        cep: '',
        seller: null,
        success: false,
        fetching: false,
        isSubmitting: false,
        sellerCommission: {
          commissionDefault: true,
          commissionBusiness: false,
          commissionBusinessValue: '',
          commissionDefaultValue: '',
        },
        sellerLegalRepresentatives: {},
        selectedPlatform: {},
        items: [],
        antecipationComission: {},
        commissionYearsHistory: [],
        campaignYears: {},
        loadingCampaigns: false,
      };
    case '@seller/FETCH_SELLER':
      return {
        ...state,
        seller: null,
        fetching: true,
      };
    case '@seller/FETCH_SELLER_SUCCESS':
      return {
        ...state,
        seller: payload,
        fetching: false,
      };
    case '@seller/FETCH_LEGAL_REPRESENTATIVE_SUCCESS':
      return {
        ...state,
        sellerLegalRepresentatives: { ...payload, fetching: false },
        fetching: false,
      };
    case '@seller/FETCH_SELLER_ERROR':
      return {
        ...state,
        ...payload,
        isSubmitting: false,
        submitted: true,
        fetching: false,
      };
    case '@seller/UPDATE_SELLER': {
      return {
        ...state,
        isSubmitting: true,
        submitted: false,
      };
    }
    case '@seller/UPDATE_SELLER_SUCCESS':
      return {
        ...state,
        ...payload,
        isSubmitting: false,
        submitted: true,
        success: true,
      };
    case '@seller/UPDATE_SELLER_ERROR':
      return {
        ...state,
        ...payload,
        isSubmitting: false,
        submitted: true,
        success: false,
      };
    case '@seller/FETCH_SELLER_COMMISSION_SUCCESS':
    case '@seller/FETCH_INTEGRATION_PLATAFORMS_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@seller/FETCH_INTEGRATION_PLATAFORMS_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@seller/CREATE_SELLER': {
      return {
        ...state,
        isSubmitting: true,
        submitted: false,
        formikFormInputValues: payload,
      };
    }
    case '@seller/CREATE_SELLER_SUCCESS':
      return {
        ...state,
        ...payload,
        isSubmitting: false,
        submitted: true,
        formikFormInputValues: {},
        sellerAddress: {},
      };
    case '@seller/CREATE_SELLER_ERROR':
      return {
        ...state,
        formikFormInputValues: payload,
        isSubmitting: false,
        submitted: true,
        fetching: false,
      };
    case '@seller/FETCH_ADDRESS_BY_CEP':
      return {
        ...state,
        ...payload,
        fetchingCep: true,
        sellerAddress: {
          zipCode: payload.cep,
        },
      };
    case '@seller/FETCH_CEP_SUCCESS':
      return {
        ...state,
        ...payload,
        fetchingCep: false,
      };
    case '@seller/FETCH_CEP_ERROR':
      return {
        ...state,
        ...payload,
        fetchingCep: false,
      };
    case '@seller/SEND_FILE':
      return {
        ...state,
        fetching: true,
        [payload.inputName]: {
          loadingFile: true,
        },
      };
    case '@seller/FILE_SENT':
      return {
        ...state,
        fetching: false,
        [payload.inputName]: {
          loadingFile: false,
          hasError: false,
          fileKey: payload.fileKey,
        },
      };
    case '@seller/FILE_SEND_FAILED':
      return {
        ...state,
        fetching: false,
        [payload.inputName]: {
          loadingFile: false,
          hasError: true,
          fileKey: '',
        },
      };
    case '@seller/FETCH_CAMPAIGNS_COMMISSION_HISTORY':
      return {
        ...state,
        loadingCampaigns: true,
      };
    case '@seller/UPDATE_COMMISSION':
    case '@seller/UPDATE_COMMISSION_REQUEST':
      return {
        ...state,
        isSubmitting: true,
        fetching: true,
      };
    case '@seller/UPDATE_COMMISSION_ERROR':
      return {
        ...state,
        isSubmitting: false,
        fetching: false,
      };
    case '@seller/UPDATE_COMMISSION_SUCCESS':
      return {
        ...state,
        commissionUpdateSuccess: true,
        isSubmitting: false,
        submitted: true,
      };
    case '@seller/FETCH_CAMPAIGNS_COMMISSION_HISTORY_SUCCESS':
    case '@seller/FETCH_PLATFORM_INTEGRATION_SUCCESS':
    case '@seller/FETCH_ANTECIPATION_COMISSION_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@seller/CREATE_SKU_COMMISSION':
      return {
        ...state,
        loading: true,
        isSubmitting: true,
      };
    case '@seller/CREATE_LEGAL_REPRESENTATIVE':
    case '@seller/UPDATE_LEGAL_REPRESENTATIVE':
    case '@seller/DELETE_LEGAL_REPRESENTATIVE':
      return {
        ...state,
        loading: true,
        isSubmitting: true,
        legalRepresentativeSaveSuccess: false,
      };
    case '@seller/CREATE_LEGAL_REPRESENTATIVE_SUCCESS':
    case '@seller/UPDATE_LEGAL_REPRESENTATIVE_SUCCESS':
    case '@seller/DELETE_LEGAL_REPRESENTATIVE_SUCCESS':
      return {
        ...state,
        legalRepresentativeSaveSuccess: true,
        isSubmitting: false,
        submitted: true,
      };
    case '@seller/CREATE_LEGAL_REPRESENTATIVE_ERROR':
    case '@seller/UPDATE_LEGAL_REPRESENTATIVE_ERROR':
    case '@seller/DELETE_LEGAL_REPRESENTATIVE_ERROR':
      return {
        ...state,
        legalRepresentativeSaveSuccess: false,
        isSubmitting: false,
        submitted: true,
      };
    case '@seller/FETCH_VIGENT_SKU_COMMISSION_ERROR':
    case '@seller/CREATE_SKU_COMMISSION_ERROR':
    case '@seller/FETCH_VIGENT_SKU_COMMISSION_SUCCESS':
    case '@seller/CREATE_SKU_COMMISSION_SUCCESS':
      return {
        ...state,
        loading: false,
        isSubmitting: false,
        ...payload,
      };
    case '@seller/DELETE_SKU_COMMISSION_CAMPAIGN_SUCCESS':
      return {
        ...state,
        commission: { ...state.commission, campaign: { ...state.commission.campaign, items: payload.items } },
      };
    case '@seller/FETCH_SKU_COMMISSION':
      return {
        ...state,
        commission: { ...state.commission, campaign: { fetching: true } },
      };
    case '@seller/FETCH_SKU_COMMISSION_SUCCESS':
      return {
        ...state,
        commission: { ...state.commission, campaign: { ...payload.campaign } },
      };
    case '@seller/FETCH_SKU_COMMISSION_ERROR':
      return {
        ...state,
        commission: { ...state.commission, campaign: { ...state.commission.campaign, ...payload } },
      };
    case '@seller/DOWNLOAD_SKU_COMMISSION': {
      const items = campaignFetchingHandle(state.commission.campaign.items, payload.campaignId, true);

      return {
        ...state,
        fetching: true,
        commission: { ...state.commission, campaign: { ...state.commission.campaign, items } },
      };
    }
    case '@seller/DOWNLOAD_SKU_COMMISSION_SUCCESS':
    case '@seller/DOWNLOAD_SKU_COMMISSION_ERROR': {
      const campaigns = campaignFetchingHandle(state.commission.campaign.items, payload.campaignId, false);

      return {
        ...state,
        fetching: false,
        commission: { ...state.commission, campaign: { ...state.commission.campaign, items: campaigns } },
      };
    }
    default:
      return state;
  }
};

function* fetchAddressByCep({ payload }: CreateCepAction) {
  try {
    const response = yield call(api.get, `${CORE_SERVICE}/zip-codes/${payload.cep}`);
    if (Object.keys(response.data).length === 0 && response.data.constructor === Object) {
      yield put({
        type: '@seller/FETCH_CEP_ERROR',
        payload: {
          sellerAddress: {
            zipCode: payload.cep,
            errorMessages: 'Endereço não encontrado.',
          },
        },
      });
    } else {
      yield put({
        type: '@seller/FETCH_CEP_SUCCESS',
        payload: {
          sellerAddress: {
            ...response.data,
            errorMessages: null,
          },
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@seller/FETCH_CEP_ERROR',
      payload: {
        sellerAddress: {
          zipCode: payload.cep,
          errorMessages: 'Endereço não encontrado.',
        },
      },
    });
  }
}

function* fetchSellerItegrationPlatformSelected({ payload }: SellerPlatformRequestAction) {
  try {
    const response = yield call(api.get, `${API_SERVICE}/integration-platforms/${payload?.id}`);

    const { id, name } = response.data;
    yield put({
      type: '@seller/FETCH_INTEGRATION_PLATAFORMS_SUCCESS',
      payload: {
        items: [{ id, name }],
        selectedPlatform: { id, name },
        fetching: false,
      },
    });
  } catch (error) {
    yield put({
      type: '@seller/FETCH_INTEGRATION_PLATAFORMS_ERROR',
      payload: {
        errorMessages: 'Algo deu errado. Por favor, tente novamente.',
        fetching: false,
      },
    });
  }
}

function* fetchIntegrationPlataformsList({ payload }: PlatformFetchListAction) {
  const {
    seller: { items },
  } = store.getState();

  const params = {
    'page-index': payload?.pageIndex || 0,
    'page-size': 10,
  };

  const isActiveParam = payload?.isActive ? '?is-active=true' : '';

  try {
    const response = yield call(api.get, `${API_SERVICE}/integration-platforms${isActiveParam}`, { params });

    yield put({
      type: '@seller/FETCH_INTEGRATION_PLATAFORMS_SUCCESS',
      payload: {
        items: items.concat(response.data.items),
        fetching: false,
        hasNextPage: response.data.hasNextPage,
        pageIndex: response.data.pageIndex + 1,
      },
    });
  } catch (error) {
    yield put({
      type: '@seller/FETCH_INTEGRATION_PLATAFORMS_ERROR',
      payload: {
        errorMessages: 'Algo deu errado. Por favor, tente novamente.',
        fetching: false,
      },
    });
  }
}

function* createSeller({ payload }: CreateSellerAction) {
  if (payload) {
    const {
      address,
      bank,
      brand,
      businessDisplayName,
      businessName,
      cnpj,
      users,
      description,
      contractSignedByDocusign,
      acceptanceDate,
      emailResponsible,
      information,
      accountName,
      affiliateId,
      applicationKey,
      applicationToken,
      salesChannel,
      isMultiStore,
      oi,
      integrationPlatformName,
    } = payload;
    let platformValues: { key: string; value: string }[] = [];
    if (integrationPlatformName === 'VTEX') {
      platformValues = [
        {
          key: 'account-name',
          value: accountName,
        },
        {
          key: 'affiliate-id',
          value: affiliateId,
        },
        {
          key: 'application-key',
          value: applicationKey,
        },
        {
          key: 'application-token',
          value: applicationToken,
        },
        {
          key: 'sales-channel',
          value: salesChannel,
        },
      ];
    } else if (integrationPlatformName.toUpperCase() === 'ANYMARKET') {
      platformValues = [
        {
          key: 'oi',
          value: oi,
        },
      ];
    }

    const {
      seller: {
        acceptanceTermFile,
        generalConditionGuidelinesFile,
        attendancePolicyAftermarketFile,
        serviceLevelAgreementFile,
      },
    } = store.getState();

    const seller = {
      address: {
        ...address,
        zipcode: address.zipcode.replace(/-/g, ''),
      },
      bank,
      brand,
      businessDisplayName,
      businessName,
      cnpj,
      users: users.map(({ groupId, fullName, email, phoneNumber }: IUser) => {
        return {
          groupId,
          fullName,
          email,
          phoneNumber: phoneNumber.replace(/\D/g, ''),
        };
      }),
      description,
      contracts: {
        acceptanceTermFile: acceptanceTermFile.fileKey,
        contractSignedByDocusign,
        acceptanceDate: contractSignedByDocusign ? new Date(acceptanceDate).toISOString().slice(0, 10) : undefined,
        emailResponsible: contractSignedByDocusign ? emailResponsible : undefined,
        generalConditionGuidelinesFile: contractSignedByDocusign ? generalConditionGuidelinesFile.fileKey : undefined,
        attendancePolicyAftermarketFile: contractSignedByDocusign ? attendancePolicyAftermarketFile.fileKey : undefined,
        serviceLevelAgreementFile: contractSignedByDocusign ? serviceLevelAgreementFile.fileKey : undefined,
      },
      information: {
        ...information,
        postZipcode: information.postZipcode.replace(/-/g, ''),
        platformValues,
      },
      isMultiStore,
    };

    let snackbarType;
    let messageSnack;

    try {
      const response = yield call(api.post, `${API_SERVICE}/sellers`, seller);
      messageSnack = 'Seller cadastrado com sucesso';
      snackbarType = 'success';
      yield put({
        type: '@seller/FETCH_SELLER_COMMISSION_SUCCESS',
        payload: {
          sellerCommission: {
            commissionDefault: true,
            commissionBusiness: false,
            commissionBusinessValue: '',
            commissionDefaultValue: response?.data?.commission?.value,
          },
        },
      });
      yield put({
        type: '@seller/CREATE_SELLER_SUCCESS',
        payload: {
          ...response,
          fetching: false,
          sellerId: response?.data?.id,
          commission: response?.data?.commission,
        },
      });
    } catch (error: any) {
      snackbarType = 'error';
      yield put({
        type: '@seller/CREATE_SELLER_ERROR',
        payload: {
          ...payload,
          ...error.response,
          fetching: false,
        },
      });
      messageSnack =
        error?.status === 409
          ? 'O seller informado já existe! Revise as informações e tente novamente!'
          : 'Ocorreu um erro ao adicionar o seller';
    } finally {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: messageSnack,
          type: snackbarType,
          open: true,
        },
      });
    }
  }
}

function* verifyVtexIntegration({ payload }: CreateSellerAction) {
  if (payload) {
    let message;
    try {
      const { accountName, applicationKey, applicationToken, type } = payload;

      const data = {
        accountName,
        applicationKey,
        applicationToken,
      };

      yield call(api.post, `${API_SERVICE}/integrations/vtex`, data);

      if (type === 'UPDATE') {
        yield put({
          type: '@seller/UPDATE_SELLER',
          payload: payload,
        });
      } else {
        yield put({
          type: '@seller/CREATE_SELLER',
          payload: payload,
        });
      }
    } catch (error: any) {
      const errors = {
        401: 'Chave vtex inválida!',
        403: 'Chave vtex inválida!',
        404: 'Account name inválido!',
        500: 'Serviço de validação vtex indisponível. Tente novamente mais tarde!',
        1000: 'Insucesso na validação dos dados vtex! Tente novamente mais tarde ou entre em contato com o suporte da VTEX',
        1001: 'Entre em contato com o suporte VTEX, informando a mensagem: InvalidCredencials: AppKey e AppToken inválidas!',
        1002: 'Entre em contato com o suporte VTEX, informando a mensagem: NotHavePermission: AppKey e AppToken sem permissão!',
        1003: 'Vtex store inativa! Tente novamente mais tarde ou entre em contato com o suporte da VTEX',
        1004: 'Account name inválido! Em caso de dúvidas, entre em contato com o suporte da VTEX',
      };

      message = errors[error.data.errorMessages[0].code as keyof IErrorMessage] || errors[1000];

      yield put({
        type: '@seller/CREATE_SELLER_ERROR',
        payload: {
          ...payload,
          errorMessages: {
            ...error,
            status: error.status,
            message,
          },
          fetching: false,
        },
      });
    } finally {
      if (message) {
        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message,
            type: 'error',
            open: true,
          },
        });
      }
    }
  }
}

function* sendContent({ payload }: SendFileAction) {
  const formData = new FormData();
  formData.append('file', payload.file);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    transformRequest: (formData: FormData) => formData,
  };

  try {
    const response = yield call(api.post, `${CORE_SERVICE}/files/uploads`, formData, config);
    if (response?.data?.fileKey) {
      yield put({
        type: '@seller/FILE_SENT',
        payload: {
          fileKey: response.data.fileKey,
          inputName: payload.inputName,
        },
      });
    } else {
      yield put({
        type: '@seller/FILE_SEND_FAILED',
        payload: {
          inputName: payload.inputName,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@seller/FILE_SEND_FAILED',
      payload: {
        inputName: payload.inputName,
      },
    });
  }
}

function* updateCommissionAndAnticipation({ payload }: UpdateCommissionAction) {
  let snackBarMessage = 'Alterações salvas com sucesso!';
  let snackbarType = 'success';
  try {
    yield call(api.patch, `${REACT_APP_COMMISSION_API_URL_V2}/commissions/${payload?.id}/seller`, payload);
    yield put({
      type: '@seller/UPDATE_COMMISSION_SUCCESS',
    });
  } catch (error) {
    snackbarType = 'error';
    snackBarMessage = 'Algo deu errado. Por favor, tente novamente.';
    yield put({
      type: '@seller/UPDATE_COMMISSION_ERROR',
    });
  } finally {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: snackBarMessage,
        type: snackbarType,
        open: true,
      },
    });
  }
}

function* fetchSeller({ payload }: UpdateSellerAction) {
  yield put({
    type: '@seller/FETCH_INTEGRATION_PLATAFORMS',
  });
  if (payload) {
    try {
      const response = yield call(api.get, `${REACT_APP_SELLER_API_URL}/portals/sellers/${payload.sellerId}`);
      yield put({
        type: '@seller/FETCH_SELLER_SUCCESS',
        payload: { ...response.data },
      });
    } catch (error) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao listar os dados do seller',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* updateSeller({ payload }: UpdateSellerAction) {
  const { seller } = store.getState();

  if (payload) {
    const {
      address,
      bank,
      description,
      businessDisplayName,
      businessName,
      id,
      information,
      accountName,
      affiliateId,
      applicationKey,
      applicationToken,
      salesChannel,
      acceptanceTermFile,
      integrationPlatformName,
      oi,
    } = payload;
    let platformValues: { key: string; value: string }[] = [];
    if (integrationPlatformName === 'VTEX') {
      platformValues = [
        {
          key: 'account-name',
          value: accountName,
        },
        {
          key: 'affiliate-id',
          value: affiliateId,
        },
        {
          key: 'application-key',
          value: applicationKey,
        },
        {
          key: 'application-token',
          value: applicationToken,
        },
        {
          key: 'sales-channel',
          value: salesChannel,
        },
      ];
    } else if (integrationPlatformName.toUpperCase() === 'ANYMARKET') {
      platformValues = seller.seller.information?.platformValues;
      const oiIndex = platformValues.findIndex((item: { key: string; value: string }) => item.key === 'oi');

      if (oiIndex === -1) {
        platformValues.push({
          key: 'oi',
          value: oi,
        });
      } else {
        platformValues[oiIndex].value = oi;
      }
    }

    const sellerPayload = {
      address: {
        ...address,
        zipcode: address.zipcode.replace(/-/g, ''),
      },
      bank,
      businessDisplayName,
      businessName,
      description,
      sellerId: id,
      information: {
        ...information,
        postZipcode: information.postZipcode.replace(/-/g, ''),
        platformValues,
      },
      contracts: {
        acceptanceTermFile: seller.acceptanceTermFile.fileKey || acceptanceTermFile.name,
      },
    };

    let snackbarType;
    let messageSnack;

    try {
      const response = yield call(api.put, `${REACT_APP_SELLER_API_URL}/portals/sellers/${id}`, sellerPayload);
      messageSnack = 'Seller atualizado com sucesso';
      snackbarType = 'success';
      yield put({
        type: '@seller/UPDATE_SELLER_SUCCESS',
        payload: {
          ...response,
          fetching: false,
        },
      });
    } catch (error: any) {
      snackbarType = 'error';
      yield put({
        type: '@seller/UPDATE_SELLER_ERROR',
        payload: {
          ...payload,
          ...error.response,
          fetching: false,
        },
      });
      messageSnack =
        error?.status === 409
          ? 'O seller informado já existe! Revise as informações e tente novamente!'
          : 'Ocorreu um erro ao atualizar o seller';
    } finally {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: messageSnack,
          type: snackbarType,
          open: true,
        },
      });
    }
  }
}

function* fetchSellerCommissionAndAnticipation({ payload }: UpdateSellerAction) {
  if (payload) {
    try {
      const response = yield call(
        api.get,
        `${REACT_APP_COMMISSION_API_URL_V2}/commission/seller?context-type=Seller&context-id=${payload.sellerId}`,
      );
      yield put({
        type: '@seller/FETCH_SELLER_COMMISSION_SUCCESS',
        payload: {
          sellerCommission: {
            id: response?.data?.id,
            commissionDefault: !response?.data?.isNegotiatedCommission,
            commissionBusiness: response?.data?.isNegotiatedCommission,
            commissionBusinessValue: response?.data?.value,
            commissionDefaultValue: response?.data?.defaultCommissionValue,
          },
          antecipationComission: { ...response?.data?.anticipation },
        },
      });
    } catch (error) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao listar a comissão do seller',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* createSkuCommission({ payload }: any) {
  if (payload) {
    try {
      const formData = new FormData();
      formData.append('name', payload.name);
      formData.append('EstimatedFinishDate', payload.endDate);
      formData.append('file', payload.file);
      formData.append('sellerId', payload.sellerId);

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        transformRequest: (formData: FormData) => formData,
      };

      yield call(api.post, `${REACT_APP_COMMISSION_API_URL}/campaigns`, formData, config);
      yield put({
        type: '@seller/CREATE_SKU_COMMISSION_SUCCESS',
        payload: {
          loading: false,
        },
      });
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Comissão por SKU cadastrada com sucesso!',
          type: 'success',
          open: true,
        },
      });
    } catch (error) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao cadastrar uma comissão por SKU.',
          type: 'error',
          open: true,
        },
      });
      yield put({
        type: '@seller/CREATE_SKU_COMMISSION_ERROR',
        payload: {
          loading: false,
        },
      });
    }
  }
}

function* fetchSkuCommission({ payload }: CommissionListAction) {
  if (payload) {
    const { sellerId, pageIndex = 0, pageSize = 50 } = payload;
    try {
      const response = yield call(
        api.get,
        `${REACT_APP_COMMISSION_API_URL}/campaigns?sellerId=${sellerId}&page-index=${pageIndex}&page-size=${pageSize}`,
      );

      yield put({
        type: '@seller/FETCH_SKU_COMMISSION_SUCCESS',
        payload: {
          campaign: { ...response?.data, fetching: false },
        },
      });
    } catch (error: any) {
      yield put({
        type: '@seller/FETCH_SKU_COMMISSION_ERROR',
        payload: {
          ...initialState,
          ...error.data,
        },
      });
    }
  }
}

function* downloadSkuCommission({
  payload,
}: SellerAction<{ campaignId: string; startDate: string; estimatedFinishDate: string; sellerId: string }>) {
  if (payload) {
    const { campaignId, startDate, estimatedFinishDate, sellerId } = payload;
    try {
      const response = yield call(
        api.get,
        `${REACT_APP_COMMISSION_API_URL}/campaigns/${campaignId}/processing-result`,
        {
          responseType: 'blob',
        },
      );

      const url = new Blob([response]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(url);
      const startDateFormated = moment(startDate as unknown as Date).format('DDMMYYYY');
      const estimatedFinishDateFormated = moment(estimatedFinishDate as unknown as Date).format('DDMMYYYY');
      link.setAttribute(
        'download',
        `${sellerId}-${startDateFormated}-${estimatedFinishDateFormated}-${campaignId}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Download concluído',
          type: 'success',
          open: true,
        },
      });

      yield put({
        type: '@seller/DOWNLOAD_SKU_COMMISSION_SUCCESS',
        payload: { campaignId },
      });
    } catch (error) {
      yield put({
        type: '@seller/DOWNLOAD_SKU_COMMISSION_ERROR',
        payload: { campaignId },
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao fazer o download',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* fetchSellerCampaignsFile({ payload }: FetchCampaignsFileAction) {
  if (payload) {
    try {
      const response = yield call(
        api.get,
        `${REACT_APP_COMMISSION_API_URL}/commission/current-commissions?sellerId=${payload.sellerId}&contextType=sku`,
        {
          responseType: 'blob',
        },
      );
      if (response) {
        const blob = new Blob([response]);
        const url = URL.createObjectURL(blob);
        yield put({
          type: '@seller/FETCH_VIGENT_SKU_COMMISSION_SUCCESS',
          payload: {
            fileUrl: url,
          },
        });
      }
    } catch (error) {
      yield put({
        type: '@seller/FETCH_VIGENT_SKU_COMMISSION_ERROR',
        payload: {
          loading: false,
          fileUrl: '',
        },
      });
    }
  }
}

function* deleteSellerCampaign({ payload }: DeleteCampaignAction) {
  if (payload) {
    const {
      seller: {
        commission: { campaign },
      },
    } = store.getState();

    try {
      yield call(api.delete, `${REACT_APP_COMMISSION_API_URL_V2}/campaigns/${payload.campaignId}/notification`);
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Exclusão de comissão por SKU realizada com sucesso!',
          type: 'success',
          open: true,
        },
      });
      yield put({
        type: '@seller/DELETE_SKU_COMMISSION_CAMPAIGN_SUCCESS',
        payload: {
          items: campaign.items.filter((campaignItem: { id: string }) => campaignItem.id !== payload.campaignId),
        },
      });
    } catch (error) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao deletar, por favor tente novamente.`,
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* fetchSellerPlatformName({ payload }: FetchSellerPlatformNameAction) {
  if (payload) {
    try {
      const response = yield call(
        api.get,
        `${REACT_APP_SELLER_API_URL}/sellers/${payload.sellerId}/integration-platforms`,
      );

      yield put({
        type: '@seller/FETCH_PLATFORM_INTEGRATION_SUCCESS',
        payload: {
          platformName: response.data.name,
        },
      });
    } catch (error) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao tentar verificar plataforma integradora do seller, por favor tente novamente.`,
          type: 'error',
          open: true,
        },
      });
    }
  }
}

const callCreateLegal = (sellerId: string, legal: ILegalRepresentative) =>
  call(api.post, `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/legal-representatives`, {
    document: legal.document,
    fullName: legal.fullName,
    email: legal.email,
    phone: legal?.phone?.replace(/[[\].!'@,><|://\\;&*()_+=-]/g, ''),
  });

const callUpdateLegal = (sellerId: string, legal: any) =>
  call(api.put, `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/legal-representatives/${legal.id}`, {
    document: legal.document,
    fullName: legal.fullName,
    email: legal.email,
    phone: legal?.phone?.replace(/[[\].!'@,><|://\\;&*()_+=-]/g, ''),
  });

function* createLegalRepresentative({ payload: { legal, sellerId } }: CreateLegalAction) {
  try {
    yield callCreateLegal(sellerId, legal);

    yield put({
      type: '@seller/CREATE_LEGAL_REPRESENTATIVE_SUCCESS',
      payload: {
        loading: false,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Representante legal cadastrado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error: any) {
    if (error?.data.errorMessages[0].message === 'Operation not effected. LegalRepresentative already registered.') {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Email duplicado, por favor cadastre o representante legal com outro email`,
          type: 'error',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao cadastrar o representante legal.',
          type: 'error',
          open: true,
        },
      });
    }

    yield put({
      type: '@seller/CREATE_LEGAL_REPRESENTATIVE_ERROR',
      payload: {
        loading: false,
      },
    });
  }
}

function* updateLegalRepresentative({ payload: { legal, sellerId } }: CreateLegalAction) {
  try {
    yield callUpdateLegal(sellerId, legal);

    yield put({
      type: '@seller/UPDATE_LEGAL_REPRESENTATIVE_SUCCESS',
      payload: {
        loading: false,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Representante legal editado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error: any) {
    if (error?.data.errorMessages[0].message === 'Operation not effected. LegalRepresentative already registered.') {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Email duplicado, por favor cadastre o representante legal com outro email`,
          type: 'error',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao editar o representante legal.',
          type: 'error',
          open: true,
        },
      });
    }

    yield put({
      type: '@seller/UPDATE_LEGAL_REPRESENTATIVE_ERROR',
      payload: {
        loading: false,
      },
    });
  }
}

function* deleteLegalRepresentative({ payload: { legalRepresentativeId, sellerId } }: DeleteLegalAction) {
  try {
    yield call(
      api.delete,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/legal-representatives/${legalRepresentativeId}`,
    );
    yield put({
      type: '@seller/DELETE_LEGAL_REPRESENTATIVE_SUCCESS',
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Representante legal removido com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao remover o representante legal.',
        type: 'error',
        open: true,
      },
    });

    yield put({
      type: '@seller/DELETE_LEGAL_REPRESENTATIVE_ERROR',
    });
  }
}

function* fetchLegalRepresentative({ payload: { sellerId, pageIndex = 0 } }: CreateLegalAction) {
  try {
    const response = yield call(
      api.get,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/legal-representatives?page-index=${pageIndex}&page-size=20`,
    );
    yield put({
      type: '@seller/FETCH_LEGAL_REPRESENTATIVE_SUCCESS',
      payload: response?.data,
    });
  } catch (error: any) {
    if (error?.status !== 404) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao listar os representantes legais do seller',
          type: 'error',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@seller/FETCH_LEGAL_REPRESENTATIVE_SUCCESS',
        payload: { success: true, items: [], indexFrom: 0, totalCount: 0, totalPages: 0, pageIndex: 0 },
      });
    }
  }
}

function* createAtencipationComission({ payload }: CreateAntecipationComission) {
  try {
    const response = yield call(api.post, `${REACT_APP_COMMISSION_API_URL_V2}/commission/seller`, {
      ...payload,
      contextType: 'Seller',
      contextId: payload.sellerId,
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Alterações salvas com sucesso!',
        type: 'success',
        open: true,
      },
    });
    yield put({
      type: '@seller/FETCH_ANTECIPATION_COMISSION_SUCCESS',
      payload: {
        antecipationComission: response?.data,
      },
    });
    yield put({ type: '@seller/UPDATE_COMMISSION_SUCCESS' });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
    yield put({ type: '@seller/UPDATE_COMMISSION_ERROR' });
  }
}

function* updateAtencipationComission({ payload }: CreateAntecipationComission) {
  try {
    const response = yield call(
      api.patch,
      `${REACT_APP_SELLER_API_URL}/sellers/${payload?.sellerId}/antecipation`,
      payload,
    );
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Alterações salvas com sucesso!',
        type: 'success',
        open: true,
      },
    });
    yield put({
      type: '@seller/FETCH_ANTECIPATION_COMISSION_SUCCESS',
      payload: {
        antecipationComission: response?.data,
      },
    });
    yield put({ type: '@seller/UPDATE_COMMISSION_SUCCESS' });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao alterar a antecipação da comissão',
        type: 'error',
        open: true,
      },
    });
    yield put({ type: '@seller/UPDATE_COMMISSION_ERROR' });
  }
}

function* fetchCampaignsHistoryYears({ payload }: FetchCommissionCamapaignHistory) {
  try {
    const { sellerId, pageIndex = 0, pageSize = 100 } = payload;
    const {
      seller: { campaignYears },
    } = store.getState();
    const response = yield call(
      api.get,
      `${REACT_APP_COMMISSION_API_URL_V2}/campaigns-history?page-index=${pageIndex}&page-size=${pageSize}&sellerId=${sellerId}&year=${
        payload?.year || ''
      }`,
    );
    if (!payload?.year) {
      yield put({
        type: '@seller/FETCH_CAMPAIGNS_COMMISSION_HISTORY_SUCCESS',
        payload: {
          commissionYearsHistory: response?.data?.items?.[0]?.years,
          loadingCampaigns: false,
        },
      });
    } else {
      yield put({
        type: '@seller/FETCH_CAMPAIGNS_COMMISSION_HISTORY_SUCCESS',
        payload: {
          campaignYears: { ...campaignYears, [payload?.year]: response?.data?.items },
          loadingCampaigns: false,
        },
      });
    }
  } catch (error: any) {
    if (error.status !== 404) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao listar histórico de campanhas',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* createSkuCommissionV2({ payload }: any) {
  if (payload) {
    try {
      const formData = new FormData();
      formData.append('name', payload.name);
      formData.append('finishDate', payload.finishDate);
      formData.append('startDate', payload.startDate);
      formData.append('file', payload.file);
      formData.append('sellerId', payload.sellerId);

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        transformRequest: (formData: FormData) => formData,
      };

      yield call(api.post, `${REACT_APP_COMMISSION_API_URL_V2}/campaigns`, formData, config);
      yield put({
        type: '@seller/CREATE_SKU_COMMISSION_SUCCESS',
        payload: {
          loading: false,
        },
      });
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Comissão por SKU cadastrada com sucesso!',
          type: 'success',
          open: true,
        },
      });
    } catch (error: any) {
      let message = 'Ocorreu um erro ao cadastrar uma comissão por SKU.';
      if (error?.status === 400) {
        message = 'Arquivo inválido! Utilize o template/arquivo da página';
      }
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message,
          type: 'error',
          open: true,
        },
      });
      yield put({
        type: '@seller/CREATE_SKU_COMMISSION_ERROR',
        payload: {
          loading: false,
        },
      });
    }
  }
}

function* sellerSaga() {
  yield takeLatest('@seller/CREATE_SELLER', createSeller);
  yield takeLatest('@seller/FETCH_INTEGRATION_PLATAFORMS', fetchIntegrationPlataformsList);
  yield takeLatest('@seller/FETCH_ADDRESS_BY_CEP', fetchAddressByCep);
  yield takeLatest('@seller/VERIFY_INTEGRATION', verifyVtexIntegration);
  yield takeEvery('@seller/SEND_FILE', sendContent);
  yield takeEvery('@seller/UPDATE_COMMISSION_AND_ANTICIPATION', updateCommissionAndAnticipation);
  yield takeLatest('@seller/FETCH_SELLER', fetchSeller);
  yield takeLatest('@seller/UPDATE_SELLER', updateSeller);
  yield takeLatest('@seller/FETCH_SELLER_COMMISSION_AND_ANTICIPATION', fetchSellerCommissionAndAnticipation);
  yield takeLatest('@seller/CREATE_SKU_COMMISSION', createSkuCommission);
  yield takeLatest('@seller/FETCH_SKU_CAMPAIGNS', fetchSellerCampaignsFile);
  yield takeLatest('@seller/FETCH_SKU_COMMISSION', fetchSkuCommission);
  yield takeLatest('@seller/DOWNLOAD_SKU_COMMISSION', downloadSkuCommission);
  yield takeLatest('@seller/DELETE_SKU_COMMISSION_CAMPAIGN', deleteSellerCampaign);
  yield takeLatest('@seller/FETCH_SELLER_PLATAFORM_NAME', fetchSellerPlatformName);
  yield takeLatest('@seller/FETCH_INTEGRATION_PLATFORM', fetchSellerItegrationPlatformSelected);
  yield takeLatest('@seller/CREATE_LEGAL_REPRESENTATIVE', createLegalRepresentative);
  yield takeLatest('@seller/UPDATE_LEGAL_REPRESENTATIVE', updateLegalRepresentative);
  yield takeLatest('@seller/DELETE_LEGAL_REPRESENTATIVE', deleteLegalRepresentative);
  yield takeLatest('@seller/FETCH_LEGAL_REPRESENTATIVE', fetchLegalRepresentative);
  yield takeLatest('@seller/CREATE_ANTECIPATION_COMMISSION', createAtencipationComission);
  yield takeLatest('@seller/UPDATE_ANTECIPATION_COMMISSION', updateAtencipationComission);
  yield takeLatest('@seller/FETCH_CAMPAIGNS_COMMISSION_HISTORY', fetchCampaignsHistoryYears);
  yield takeLatest('@seller/CREATE_SKU_COMMISSION_V2', createSkuCommissionV2);
}

export { sellerReducer, sellerSaga };
