import React from 'react';
import { SvgIconProps, SvgIcon } from '@material-ui/core';
import { Settings, ShoppingBasket, TextsmsOutlined, DeviceHub, SportsEsportsOutlined } from '@material-ui/icons';
import { UserPermission } from '../../../@types/auth';
import verifyPermission from '../../utils/verifyPermission';
import { ReactComponent as BlocklistIcon } from './assets/blocklist.svg';
import { ReactComponent as IntegrationIcon } from './assets/integration_icon.svg';
import { ReactComponent as ShoppingBagIcon } from './assets/shopping_bag_black.svg';

const CustomBlocklistIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <BlocklistIcon />
  </SvgIcon>
);

const CustomIntegrationIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <IntegrationIcon />
  </SvgIcon>
);

export interface SubMenuProps {
  label: string;
  url: string;
  requiredUserPermission: UserPermission;
  preloadPage?: string;
  match?: boolean;
}

export interface MenuProps {
  label: string;
  url?: string;
  icon: React.FC;
  requiredUserPermission: UserPermission;
  submenu?: SubMenuProps[];
}

const {
  REACT_APP_FEATURE_TOGGLE_COMMUNICATION_VIEW,
  REACT_APP_FEATURE_TOOGLE_TERMS,
  REACT_APP_FEATURE_TOGGLE_BRANDS,
  REACT_APP_FEATURE_TOGGLE_PRODUCT_VIEW,
  REACT_APP_FEATURE_TOGGLE_SELLER_VIEW,
  REACT_APP_FEATURE_TOGGLE_SELLER_LITE_VIEW,
  REACT_APP_FEATURE_TOGGLE_INTEGRATION_PLATFORM_MANAGE,
  REACT_APP_FEATURE_TOGGLE_FANLAB,
  REACT_APP_FEATURE_TOGGLE_DELIVERY_FAILURE_MANAGE,
} = process.env;

const allMenuOptions: MenuProps[] = [
  {
    label: 'Sellers',
    icon: Settings,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_VIEW,
    },
    submenu: [
      {
        preloadPage: 'Sellers',
        label: 'Gestão de Sellers',
        url: '/sellers',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-seller',
          role: REACT_APP_FEATURE_TOGGLE_SELLER_VIEW,
        },
      },
    ],
  },
  {
    label: 'Conta Lite',
    icon: CustomIntegrationIcon,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_SELLER_LITE_VIEW,
    },
    submenu: [
      {
        preloadPage: 'SellersLite',
        label: 'Gestão de Contas Lite',
        url: '/conta-lite',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-seller',
          role: REACT_APP_FEATURE_TOGGLE_SELLER_LITE_VIEW,
        },
      },
    ],
  },
  {
    label: 'Produtos',
    icon: ShoppingBasket,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-catalog',
      role: REACT_APP_FEATURE_TOGGLE_PRODUCT_VIEW,
    },
    submenu: [
      {
        label: 'Gestão de produtos',
        url: '/produtos',
        preloadPage: 'Products',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-catalog',
          role: REACT_APP_FEATURE_TOGGLE_PRODUCT_VIEW,
        },
        match: true,
      },
    ],
  },
  {
    label: 'Pedidos',
    icon: ShoppingBagIcon,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-order-sale',
      role: REACT_APP_FEATURE_TOGGLE_DELIVERY_FAILURE_MANAGE,
    },
    submenu: [
      {
        preloadPage: 'DeliveryFailures/DeliveryFailures',
        label: 'Insucesso na entrega',
        url: '/pedidos/insucesso-na-entrega',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-order-sale',
          role: REACT_APP_FEATURE_TOGGLE_DELIVERY_FAILURE_MANAGE,
        },
      },
    ],
  },
  {
    label: 'Fanlab',
    icon: SportsEsportsOutlined,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-catalog',
      role: REACT_APP_FEATURE_TOGGLE_FANLAB,
    },
    submenu: [
      {
        preloadPage: 'Fanlab/Products',
        label: 'Gestão de produtos Fanlab',
        url: '/fanlab/gestao-de-produtos',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-catalog',
          role: REACT_APP_FEATURE_TOGGLE_FANLAB,
        },
      },
    ],
  },
  {
    label: 'Blocklist',
    icon: CustomBlocklistIcon,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-core',
      role: REACT_APP_FEATURE_TOOGLE_TERMS,
    },
    submenu: [
      {
        preloadPage: 'Blocklist/Brands',
        label: 'Marcas',
        url: '/blocklist/marcas',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-core',
          role: REACT_APP_FEATURE_TOGGLE_BRANDS,
        },
      },
      {
        preloadPage: 'Blocklist/Terms',
        label: 'Termos',
        url: '/blocklist/termos',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-core',
          role: REACT_APP_FEATURE_TOOGLE_TERMS,
        },
      },
    ],
  },
  {
    label: 'Central de comunicados',
    icon: TextsmsOutlined,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-notification',
      role: REACT_APP_FEATURE_TOGGLE_COMMUNICATION_VIEW,
    },
    submenu: [
      {
        preloadPage: 'Communications/Management',
        label: 'Gestão',
        url: '/central-de-comunicados',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-notification',
          role: REACT_APP_FEATURE_TOGGLE_COMMUNICATION_VIEW,
        },
      },
    ],
  },
  {
    label: 'Plataformas',
    icon: DeviceHub,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-seller',
      role: REACT_APP_FEATURE_TOGGLE_INTEGRATION_PLATFORM_MANAGE,
    },
    submenu: [
      {
        preloadPage: 'Platforms/Management',
        label: 'Gestão de Plataformas',
        url: '/plataformas',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-seller',
          role: REACT_APP_FEATURE_TOGGLE_INTEGRATION_PLATFORM_MANAGE,
        },
      },
    ],
  },
];

const sideMenuOptions = allMenuOptions.filter((menuOption) => {
  if (!verifyPermission(menuOption.requiredUserPermission)) {
    return false;
  }

  menuOption.submenu = menuOption.submenu?.filter((optSubmenu) => verifyPermission(optSubmenu.requiredUserPermission));

  return true;
});

export { sideMenuOptions, allMenuOptions };
