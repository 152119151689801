import { useKeycloak } from '@react-keycloak/web';
import React, { Suspense } from 'react';
import { Redirect, Route, RouteProps, Router, Switch, useHistory } from 'react-router-dom';
import { CssBaseline, LinearProgress, MuiThemeProvider } from '@material-ui/core';
import { UserPermission } from '../@types/auth';
import goToLoginPage from '../shared/utils/goToLoginPage';
import verifyPermission from '../shared/utils/verifyPermission';
import rchloTheme from '../theme';
import Page403 from './Page403/Page403';
import history from './history';
import routes from './routes';

interface PrivateRouteProps extends RouteProps {
  requiredUserPermissions: UserPermission;
}

const PrivateRoute = ({ component: Component, requiredUserPermissions, ...rest }: PrivateRouteProps) => {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const { authenticated } = keycloak;

  if (!authenticated) return goToLoginPage(keycloak);

  if (history.location.pathname === '/') return <Redirect to="/sellers" />;

  if (!verifyPermission(requiredUserPermissions)) return <Redirect push to="/" />;

  return <Route {...rest} render={(props) => (Component ? <Component {...props} /> : null)} />;
};

const renderPages = routes.map(({ publicAccess, path, ...rest }) => {
  const RenderRouter = publicAccess ? Route : PrivateRoute;
  return <RenderRouter key={path} path={path} {...rest} />;
});

const Pages: React.FC = () => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) return null;

  const { resourceAccess, authenticated, tokenParsed } = keycloak;

  if (!authenticated) return goToLoginPage(keycloak);

  if (resourceAccess) {
    sessionStorage.setItem('@rchlo/admin/resource_access', JSON.stringify(resourceAccess));
  }

  return (
    <MuiThemeProvider theme={rchloTheme}>
      <CssBaseline />
      <Router history={history}>
        <Suspense fallback={<LinearProgress />}>
          {!tokenParsed?.is_corporate ? <Page403 publicMode /> : <Switch>{renderPages}</Switch>}
        </Suspense>
      </Router>
    </MuiThemeProvider>
  );
};

export default Pages;
