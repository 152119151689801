import { Reducer } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import isValidFilter from '@rchlo/storybook/core/shared/utils/isValidFilter';
import type { SellersLiteState, FetchSellersLiteAction } from '../../../@types/sellersLite';
import { api } from '../../../services/api';

const initialState: SellersLiteState = {
  fetching: false,
  success: false,
  errorMessages: [],
  items: [],
  pageIndex: 0,
  pageSize: 8,
  totalCount: 0,
  totalPages: 0,
  indexFrom: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

const sellersLiteReducer: Reducer<SellersLiteState, FetchSellersLiteAction | any> = (
  state = initialState,
  { payload, type },
) => {
  switch (type) {
    case '@sellers-lite/CLEAN':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: false,
      };
    case '@sellers-lite/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@sellers-lite/FETCH_SUCCESS':
    case '@sellers-lite/FETCH_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* fetch({ payload: { pageIndex = 0, pageSize = 8, search, isActive } }: FetchSellersLiteAction) {
  const isFilter = isValidFilter({
    isActive,
    search,
  });

  try {
    const response = yield call(api.get, `${process.env.REACT_APP_SELLER_API_URL}/lite-accounts`, {
      params: {
        pageSize,
        pageIndex,
        search,
        isActive,
      },
    });

    yield put({
      type: '@sellers-lite/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        ...response?.data,
      },
    });
  } catch (error: any) {
    if (error.status === 404) {
      const [errorMessage] = error.data.errorMessages;
      yield put({
        type: '@sellers-lite/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: isFilter
                ? 'Nenhum resultado encontrado, verifique os dados inseridos e tente novamente.'
                : 'Não existem sellers para listar.',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@sellers-lite/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
        },
      });
    }
  }
}

function* sellersLiteSaga() {
  yield takeLatest('@sellers-lite/FETCH', fetch);
}

export { sellersLiteReducer, sellersLiteSaga };
