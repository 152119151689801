import { UserPermission } from '../../@types/auth';

interface KeycloakResourceAccess {
  [key: string]: { roles: string[] };
}

const verifyPermission = (userPermission: UserPermission, resourceAccess?: KeycloakResourceAccess): boolean => {
  const resrcAccess = resourceAccess || JSON.parse(sessionStorage.getItem('@rchlo/admin/resource_access') || '{}');
  const { noExtraPermissionNeeded, api, role } = userPermission;

  if (noExtraPermissionNeeded) {
    return true;
  }

  if (!api || !role) return false;

  return resrcAccess[api]?.roles?.includes(role);
};

export default verifyPermission;
