import { Reducer } from 'react';
import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { ErrorMessages, Pagination } from '../../../@types';
import type { ICommunications, CommunicationRequest } from '../../../@types/communications';
import type { DefaultRequest } from '../../../@types/index';
import { api } from '../../../services/api';

const API_NOTIFICATION = process.env.REACT_APP_NOTIFICATION_API_URL;

export type CommunicationsState = Pagination<ICommunications> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  search?: string;
  isSubmitting: boolean;
  communication?: {
    id: string;
    sellers: string[];
    title: string;
    description: string;
    urlRedirect: string;
    exhibitionInitialDateTime: string;
    exhibitionFinalDateTime: string;
  };
};

export type CommunicationsAction<T> = Action<string> & {
  payload: T;
};

export type FetchCommunicationsAction = CommunicationsAction<DefaultRequest>;
export type CommunicationAction = CommunicationsAction<CommunicationRequest>;

const initialState: CommunicationsState = {
  fetching: false,
  success: false,
  errorMessages: [],
  items: [],
  pageIndex: 0,
  pageSize: 8,
  totalCount: 0,
  totalPages: 0,
  indexFrom: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  isSubmitting: false,
};

const communicationsReducer: Reducer<CommunicationsState, FetchCommunicationsAction | any> = (
  state = initialState,
  { payload, type },
) => {
  switch (type) {
    case '@communications/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@communications/FETCH_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@communications/FETCH_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@communication/CREATE':
      return {
        ...state,
        isSubmitting: true,
      };
    case '@communication/CANCEL_CONFIRMATION':
      return {
        ...state,
        isSubmitting: false,
      };
    case '@communication/CREATE_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
      };
    case '@communication/CREATE_ERROR':
      return {
        ...state,
        ...payload,
        isSubmitting: false,
      };
    case '@communication/UPDATE':
      return {
        ...state,
        isSubmitting: true,
      };
    case '@communication/UPDATE_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
      };
    case '@communication/UPDATE_ERROR':
      return {
        ...state,
        ...payload,
        isSubmitting: false,
      };
    case '@communications/CLEAN': {
      return {
        ...initialState,
      };
    }
    case '@communication/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@communication/FETCH_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@communication/FETCH_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* fetchCommunications({ payload: { pageIndex = 0, pageSize = 8 } }: FetchCommunicationsAction) {
  try {
    const response = yield call(api.get, `${API_NOTIFICATION}/communications`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
      },
    });

    yield put({
      type: '@communications/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        ...response?.data,
      },
    });
  } catch (error: any) {
    const notFoundHelperText = 'Não existem informações para listar.';

    if (error.status === 404 && error.data.errorMessages) {
      const [errorMessage] = error.data.errorMessages;
      yield put({
        type: '@communications/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText,
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@communications/FETCH_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              notFoundHelperText,
            },
          ],
        },
      });
    }
  }
}

function* createCommunication({ payload }: CommunicationAction) {
  const msgError = 'Ocorreu um erro ao adicionar o comunicado, por favor tente novamente.';

  try {
    const response = yield call(api.post, `${API_NOTIFICATION}/communications`, payload.values);

    yield put({
      type: '@communication/CREATE_SUCCESS',
      payload: response,
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Comunicado adicionado com sucesso!`,
        type: 'success',
        open: true,
      },
    });

    payload.callback();
  } catch (error) {
    yield put({
      type: '@communication/CREATE_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: msgError,
        type: 'error',
        open: true,
      },
    });
  }
}

function* updateCommunication({ payload }: CommunicationAction) {
  const msgError = 'Ocorreu um erro ao atualizar o comunicado, por favor tente novamente.';

  try {
    const response = yield call(api.put, `${API_NOTIFICATION}/communications/${payload?.id}`, payload.values);

    yield put({
      type: '@communication/UPDATE_SUCCESS',
      payload: response,
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Comunicado atualizado com sucesso!`,
        type: 'success',
        open: true,
      },
    });

    payload.callback();
  } catch (error) {
    yield put({
      type: '@communication/UPDATE_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: msgError,
        type: 'error',
        open: true,
      },
    });
  }
}

function* deleteCommunication({ payload }: CommunicationAction) {
  try {
    yield call(api.delete, `${API_NOTIFICATION}/communications/${payload?.id}`);

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Comunicado excluído com sucesso!`,
        type: 'success',
        open: true,
      },
    });

    yield put({
      type: '@communications/FETCH',
      payload: {
        pageIndex: payload.pageIndex,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao excluir o comunicado, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* cancelCommunication({ payload }: CommunicationAction) {
  try {
    yield call(api.patch, `${API_NOTIFICATION}/communications/${payload?.id}`, {});

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Comunicado cancelado com sucesso!`,
        type: 'success',
        open: true,
      },
    });

    yield put({
      type: '@communications/FETCH',
      payload: {
        pageIndex: payload.pageIndex,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao cancelar o comunicado, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* fetchCommunication({ payload }: CommunicationsAction<{ id: string }>) {
  try {
    const response = yield call(api.get, `${API_NOTIFICATION}/communications/${payload.id}`);
    if (response.data) {
      yield put({
        type: '@communication/FETCH_SUCCESS',
        payload: { communication: response.data },
      });
    }
  } catch (error) {
    yield put({
      type: '@communication/FETCH_ERROR',
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao listar o comunicado',
        type: 'error',
        open: true,
      },
    });
  }
}

function* communicationsSaga() {
  yield takeLatest('@communications/FETCH', fetchCommunications);
  yield takeLatest('@communication/CREATE', createCommunication);
  yield takeLatest('@communication/UPDATE', updateCommunication);
  yield takeLatest('@communication/DELETE', deleteCommunication);
  yield takeLatest('@communication/CANCEL', cancelCommunication);
  yield takeLatest('@communication/FETCH', fetchCommunication);
}

export { communicationsReducer, communicationsSaga };
