import type { Reducer } from 'redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import { FanlabAction, FanlabState, SendFileAction, FetchManagementListFanlabAction } from '../../../@types/fanlab';
import { api } from '../../../services/api';

const { REACT_APP_CORE_API_URL, REACT_APP_CATALOG_API_URL } = process.env;

const initialState: FanlabState = {
  fetching: false,
  success: false,
  errorMessages: [],
  items: [],
  pageIndex: 0,
  pageSize: 8,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
};

const reducer: Reducer<FanlabState, FanlabAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@fanlab/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@fanlab/FETCH_LIST':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@fanlab/FETCH_LIST_SUCCESS':
    case '@fanlab/FETCH_LIST_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* filesUpload({ payload }: SendFileAction) {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('fileType', payload.fileType);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    transformRequest: (formData: FormData) => formData,
  };

  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Realizando upload, isso pode demorar um pouco. Confira o histórico aqui mais tarde.',
      type: 'loading',
      open: true,
    },
  });

  try {
    const response = yield call(api.post, `${REACT_APP_CORE_API_URL}/files/uploads`, formData, config);
    if (response?.data?.fileKey) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Planilha enviada com sucesso, o processamento ocorrerá de forma assíncrona.',
          type: 'success',
          open: true,
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Operação não efetuada! Tamanho máximo de arquivo permitido: 5MB!',
        type: 'error',
        open: true,
      },
    });
  }
}

function* fetchmanagementFanlabList({ payload: { pageIndex = 0, pageSize = 8 } }: FetchManagementListFanlabAction) {
  const params = {
    pageIndex,
    pageSize,
  };

  try {
    const response = yield call(api.get, `${REACT_APP_CATALOG_API_URL}/sku-channels/uploads`, { params });
    yield put({
      type: '@fanlab/FETCH_LIST_SUCCESS',
      payload: {
        success: response.success,
        ...response.data,
      },
    });
  } catch (error: any) {
    const notFoundHelperText = 'Não existem informações para listar.';

    if (error.status === 404 && error.data.errorMessages) {
      const [errorMessage] = error.data.errorMessages;
      yield put({
        type: '@fanlab/FETCH_LIST_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText,
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@fanlab/FETCH_LIST_ERROR',
        payload: {
          ...initialState,
          ...error.data,
          errorMessages: [
            {
              notFoundHelperText,
            },
          ],
        },
      });
    }
  }
}

function* saga() {
  yield takeEvery('@fanlab/FILES_UPLOAD', filesUpload);
  yield takeEvery('@fanlab/FETCH_LIST', fetchmanagementFanlabList);
}

export { reducer as fanlabReducer, saga as fanlabSaga };
