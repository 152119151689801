import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

export const Title = styled.p`
  font-family: Larsseit;
  color: #ed6926;
  font-size: 16px;
`;

export const Footer = styled.footer`
  height: 60px;
  padding: 0 30px;
  font-size: 14px;
  color: #999999;
  background-color: white;
  margin: 0px;
  display: flex;
  width: 100%;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const MainStyled = styled.div`
  background-color: #f4f4f4;
  height: 100%;
  min-height: calc(100vh - (60px));
`;
