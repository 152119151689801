import React from 'react';
import { useSelector } from 'react-redux';
import { LayoutState } from '../../@types/layout';
import Snackbar from '../../shared/components/Snackbar';

const Communication = () => {
  const layout = useSelector<{ layout: LayoutState }>((state) => state.layout) as LayoutState;
  if (!layout) return null;
  return <Snackbar message={layout.message} type={layout.type} fullName={layout.fullName} open={layout.open} />;
};

export default Communication;
