import type { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FetchWebhookAction,
  RemoveWebhookAction,
  SendWebhookAction,
  UpdateWebhookAction,
  WebhookResponse,
  WebhookState,
} from '../../../@types/webhook';
import { api } from '../../../services/api';

const { REACT_APP_SELLER_API_URL } = process.env;

const initialState: WebhookState = {
  fetching: false,
  url: '',
  webhookId: '',
};

const webhookReducer: Reducer<WebhookState, FetchWebhookAction> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@webhook/FETCH_URL':
    case '@webhook/SEND_URL':
    case '@webhook/UPDATE_URL':
    case '@webhook/REMOVE_URL':
      return { ...state, ...payload, fetching: true };
    case '@webhook/FETCH_URL_SUCCESS':
    case '@webhook/SEND_URL_SUCCESS':
    case '@webhook/UPDATE_URL_SUCCESS':
    case '@webhook/REMOVE_URL_SUCCESS':
      return { ...state, ...payload, fetching: false };
    case '@webhook/FETCH_URL_ERROR':
    case '@webhook/SEND_URL_ERROR':
    case '@webhook/UPDATE_URL_ERROR':
    case '@webhook/REMOVE_URL_ERROR':
      return { ...state, ...payload, fetching: false };

    default:
      return state;
  }
};

function* fetchWebhookUrl({ payload: { sellerId } }: FetchWebhookAction) {
  try {
    const response: WebhookResponse = yield call(api.get, `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/webhooks`);

    yield put({
      type: '@webhook/FETCH_URL_SUCCESS',
      payload: {
        url: response.data.url,
        webhookId: response.data.id,
      },
    });
  } catch (error) {
    yield put({
      type: '@webhook/FETCH_URL_ERROR',
      payload: {
        ...initialState,
      },
    });
  }
}

function* sendWebhookUrl({ payload: { sellerId, url, callback } }: SendWebhookAction) {
  try {
    const data = { url };

    const response: WebhookResponse = yield call(
      api.post,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/webhooks`,
      data,
    );
    yield put({
      type: '@webhook/SEND_URL_SUCCESS',
      payload: {
        webhookId: response.data.id,
      },
    });
    callback();
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'URL adicionada com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error: any) {
    const errorMessages = error?.data?.erroMessages || [
      {
        message: 'Não foi possível adicionar a URL! Tente novamente mais tarde!',
      },
    ];
    yield put({
      type: '@webhook/SEND_URL_ERROR',
      payload: {
        ...initialState,
        errorMessages,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: errorMessages[0].message,
        type: 'error',
        open: true,
      },
    });
  }
}

function* updateWebhookUrl({ payload: { sellerId, webhookId, url, callback } }: UpdateWebhookAction) {
  try {
    const data = { url };

    const response: WebhookResponse = yield call(
      api.put,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/webhooks/${webhookId}`,
      data,
    );

    yield put({
      type: '@webhook/UPDATE_URL_SUCCESS',
      payload: {
        webhookId: response.data.id,
      },
    });
    callback();
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'URL editada com sucesso!!',
        type: 'success',
        open: true,
      },
    });
  } catch (error: any) {
    const errorMessages = error?.data?.erroMessages || [
      {
        message: 'Não foi possível editar a URL! Tente novamente mais tarde!',
      },
    ];
    yield put({
      type: '@webhook/UPDATE_URL_ERROR',
      payload: {
        ...initialState,
        errorMessages,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: errorMessages[0].message,
        type: 'error',
        open: true,
      },
    });
  }
}

function* removeWebhookUrl({ payload: { sellerId, webhookId, callback } }: RemoveWebhookAction) {
  try {
    yield call(api.delete, `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/webhooks/${webhookId}`);
    yield put({
      type: '@webhook/REMOVE_URL_SUCCESS',
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'URL excluída com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error: any) {
    const errorMessages = error?.data?.erroMessages || [
      {
        message: 'Não foi possível excluir a URL! Tente novamente mais tarde!',
      },
    ];

    yield put({
      type: '@webhook/REMOVE_URL_ERROR',
      payload: {
        ...initialState,
        errorMessages,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: errorMessages[0].message,
        type: 'error',
        open: true,
      },
    });
  } finally {
    callback && callback();
  }
}

function* webhookSaga() {
  yield takeLatest('@webhook/FETCH_URL', fetchWebhookUrl);
  yield takeLatest('@webhook/SEND_URL', sendWebhookUrl);
  yield takeLatest('@webhook/UPDATE_URL', updateWebhookUrl);
  yield takeLatest('@webhook/REMOVE_URL', removeWebhookUrl);
}

export { webhookReducer, webhookSaga };
