import { Reducer } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { CreateSellerLiteAction, SellerLiteState, SellersLiteAction } from '../../../@types/sellersLite';
import { api } from '../../../services/api';

const { REACT_APP_SELLER_API_URL } = process.env;

const initialState = {
  id: '',
  success: false,
  errorMessages: [],
  fetching: false,
  isSubmitting: false,
  items: [],
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  formikFormInputValues: {},
};

const sellerLiteReducer: Reducer<SellerLiteState, SellersLiteAction<any>> = (
  state = initialState,
  { payload, type },
) => {
  switch (type) {
    case '@seller-lite/CREATE_SELLER_LITE':
      return {
        ...state,
        ...payload,
        seller: null,
        fetching: true,
        isSubmitting: true,
      };
    case '@seller-lite/CREATE_SELLER_SUCCESS':
      return {
        ...state,
        seller: payload,
        fetching: false,
        isSubmitting: false,
        success: true,
      };
    case '@seller-lite/CREATE_SELLER_ERROR':
      return {
        ...state,
        ...payload,
        isSubmitting: false,
        fetching: false,
      };
    default:
      return state;
  }
};

function* createSeller({ payload }: CreateSellerLiteAction) {
  if (payload) {
    const { businessName, responsibleName, responsibleEmailAddress } = payload;

    const seller = {
      businessName,
      responsibleName,
      responsibleEmailAddress,
    };

    let snackbarType;
    let messageSnack;

    try {
      const response = yield call(api.post, `${REACT_APP_SELLER_API_URL}/lite-accounts`, seller);
      messageSnack = 'Seller cadastrado com sucesso';
      snackbarType = 'success';

      yield put({
        type: '@seller-lite/CREATE_SELLER_SUCCESS',
        payload: {
          ...response,
          fetching: false,
          id: response?.data?.id,
        },
      });
    } catch (error: any) {
      snackbarType = 'error';
      yield put({
        type: '@seller-lite/CREATE_SELLER_ERROR',
        payload: {
          ...payload,
          ...error.response,
          fetching: false,
        },
      });
      messageSnack =
        error?.status === 409
          ? 'O seller informado já existe! Revise as informações e tente novamente!'
          : 'Ocorreu um erro ao adicionar o seller';
    } finally {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: messageSnack,
          type: snackbarType,
          open: true,
        },
      });
    }
  }
}

function* sellerLiteSaga() {
  yield takeLatest('@seller-lite/CREATE_SELLER_LITE', createSeller);
}

export { sellerLiteReducer, sellerLiteSaga };
