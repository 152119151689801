import { ISeller } from '../../../@types/sellers';

export const initialValues: ISeller = {
  bank: {
    name: '',
    agencyNumber: '',
    accountNumber: '',
    code: '',
    accountNumberDigit: '',
  },
  address: {
    zipcode: '',
    streetAddress: '',
    streetAddressLine2: '',
    officeNumber: '',
    neighborhood: '',
    state: '',
    city: '',
    country: 'Brasil',
  },
  information: {
    freeInstallmentLimit: 1,
    stateRegistrationNumber: '',
    legalName: '',
    webSite: '',
    integrationPlatformId: '',
    postZipcode: '',
    sellToBusiness: false,
    sapId: '',
    mcc: '',
  },
  users: [
    {
      groupId: process.env.REACT_APP_ADMIN_USER_ID as string,
      groupName: 'Administrativo',
      fullName: '',
      email: '',
      phoneNumber: '',
      isAdmin: true,
    },
  ],
  businessName: '',
  businessDisplayName: '',
  description: '',
  cnpj: '',
  sellerId: '',
  id: '',
  isMultiStore: false,
  brand: '',
  contractSignedByDocusign: false,
  contracts: [],
  acceptanceDate: '',
  emailResponsible: '',
  acceptanceTermFile: {
    name: '',
    type: '',
    link: '',
  },
  generalConditionGuidelinesFile: {
    name: '',
    type: '',
    link: '',
  },
  attendancePolicyAftermarketFile: {
    name: '',
    type: '',
    link: '',
  },
  serviceLevelAgreementFile: {
    name: '',
    type: '',
    link: '',
  },
  integrationPlatformName: '',
  salesChannel: '',
  affiliateId: '',
  accountName: '',
  applicationKey: '',
  applicationToken: '',
  oi: '',
};
